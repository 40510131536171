
import axios from '../../axiosconfig';
// user
// export function fetchUserType() {
//    return  new Promise((resolve,reject)=>{ 
//     axios.get('user/api/v1/usertype/list')
//       .then(function (response) {
//         resolve(response.data);
//       })
//       .catch(function (error) {
//         reject(error);
//         // console.log('Error on Authentication');
//       });
//     });
// }
// // master addSchoolMasterData
export function dashboardDetails(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`lms-assessment-service/api/v1/lms_assessment/data/mgmt/beneficiary/dashboard/${id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function dashboardGraphDetails(year) {
  return new Promise((resolve, reject) => {
    axios
      // .get(`api/v1/lms-admin/admin/dashboard/userTrends/${year}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// export function dashboardDetails() {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`/api/v1/lms_assessment/data/mgmt/beneficiary/dashboard/${id}`)
//       .then(function (response) {
//         resolve(response.data);
//       })
//       .catch(function (error) {
//         reject(error);
//       });
//   });
// }
export function addSchoolMasterDataMasterApi(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('master/api/vl/lms-master/lmsSchoolMaster/addSchoolMasterData',data,{
    headers: {
        //'Access-Control-Allow-Origin':'*',
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
  })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// // get All value By Category Code

export function categorycodeMasterApi(schoolId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`master/api/v1/lms-master/lmsSubCategoryMaster/getAllValueByCategoryCode/${schoolId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// get All SubCategory Master

export function subcategoryMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsSubCategoryMaster/getAllSubCategoryMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// get All State City Name By Sate Id

export function getallstatecitMasterApi(stateId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`master/api/v1/lms-master/lmsStateCityName/getAllStateCityNameBySateId/${stateId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

// get  lms SchoolMasterMaster

export function getlmsSchooIMasterMasterApi(schoolId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`master/api/v1/lms-master/lmsSchoolMaster/${schoolId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// get All School Master

export function getAllSchoolMasterMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsSchoolMaster/getAllSchoolMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//get All Gis State Master Master
//state
export function getAllGisStateMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsGisSateMasterMaster/getAllGisStateMasterMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//get All Gis Postal Code Master
//pincode
export function getAllGisPostalMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsGisPostalCodeMaster/getAllGisPostalCodeMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}


//get All Gis City Master Code Master
//city
export function getAllGisCityMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsGisCityMasterMaster/getAllGisCityMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
// get All Category Master
//category
export function getAllCategoryMasterApi() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('master/api/v1/lms-master/lmsCategoryMaster/getAllCategoryMaster')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//category
export function getAllMasterByCategoryCode(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`master/api/v1/lms-master/lmsCategoryMaster/getCategoryMasterByCategoryCode/${id}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
