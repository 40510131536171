import Keycloak from 'keycloak-js';
import { ENV } from '../util/env';
const keycloak = Keycloak({
    url: ENV.API_KEY_URL,
    realm: "lms",
    // clientId: "ll",
    clientId:  "lms_beneficiary_portal",
    onLoad: "login-requried"

});


// const keycloak = Keycloak({
//     url: 'http://localhost:8080/auth',
//     realm: "Kotak-lms",
//     // clientId: "ll",
//     clientId: "Oli Ganguly",
//     onLoad: "login-requried"

// });

// url: 'http://lmsuserkey.finlabsindia.org/auth',
// realm: "lms_test",
// clientId: "lms-admin-portal",
// onLoad: "login-requried",


export default keycloak;

