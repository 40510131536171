import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { filterLesson,fetchModuleData,filterLessonByStatus1 } from "../../../services/ApiCaller/content/lesson";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";


// import imgicon from "../../../template/img/image_icon.svg";


// import { CustomErrorComponent } from 'custom-error';

export default function Lesson() {
  const { id } = useParams()
  const navigate = useNavigate();
  const file = 'https://drive.google.com/file/d/0B-lBHFdNJhiGS0JSUGVoeFowTG82YW1iZ2xIb09yemVMdkJJ/view?usp=sharing&resourcekey=0-nLMUZcQeGQfROpAmpe6AQg'
  const type = 'png'

  //LOADER + CURRENT URL
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  // const[unit,unitDetails]=useState([])
  const [allLessons, setAllLessons] = useState([])
  const [unitname, setUnitname] = useState([])

  //Error Msgs
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  useEffect(() => {
    fetchUrl();
    fetchLessonByUnitId()
  }, []);
  //FETCH URL TO REFLECT IN SIDE BAR
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };
  const fetchLessonByUnitId = () => {
    setLoading(true);
    fetchModuleData(id)
    filterLessonByStatus1(id)
    const Data = JSON.stringify({
      "unitId": id,
    })
    const response = filterLesson(Data);
    response.then(
      (success) => {
        setLoading(false);
        Object.values(success.filter((success) => success.status === "Approved")).map((item) => {
          setAllLessons((prevData) => [...prevData, item])
          console.log("item", item);
        });
        // fetchContent(success.id)
        Object.values(success).map((item) => {
          setUnitname(item.unit.name)
        });
        // unitDetails(success.unit)
      },
      (error) => {
        setLoading(false);
        setAllLessons([])
        setErrorObj(error);
        setShowpopup(true);
        setPopup(true);
      }
    );
  };

  const fetchContent = (lessonId) => {
    const currPath = window.location.pathname
    console.log(currPath,"currPath",id)
    navigate(`/Lesson/${id}/LessonContent/${lessonId}`,
      {
        state: id
      })
  };

  return (
    <>
      <Header />
      <div className=" app">
        <Sidebar url={currentUrl} />
        <main>
          {!loading ? (
            <>
              <div className="mainpage">
                <div className="App">
                  <div>
                    <div className="row justify-content-between">
                      <div className="col">
                        <button
                          type="button"
                          title="Back to materials"
                          className="btn backbtnunit  btn-sm"
                          onClick={() => navigate("/Materials")}
                        >
                          <i className="fa-solid fa-circle-arrow-left"></i>
                        </button>

                        <a className="text-primary text-decoration-underline itemhover"
                          onClick={() => navigate("/Materials")} >
                          unit: {unitname}
                        </a>

                      </div>
                    </div>

                    <div className="row">

                      {
                        allLessons && allLessons.map((item, index) => {

                          return (
                            <div className="col-4 Lessoncard">
                              <details className="details3">
                                <summary
                                  onClick={() => fetchContent(item.id)}>
                                  Lesson {index + 1} : {item.name}
                                </summary>
                              </details>
                            </div>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              </div>
              {showPopup ?
                <ErrorMsg
                  errorStatus={errorObj}
                  setTrigger={setShowpopup}
                  trigger={popup}
                /> : ''}
            </>
          ) : (
            <Loader />
          )
          }
        </main >
      </div >
    </>
  );
}
