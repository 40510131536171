import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userUIRole } from "../../../util/roleName";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from "../../DefaultComponent/Loader";
import SuccessMsg from "../../DefaultComponent/SuccessMsg";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import bannerImg from './../../../template/img/Reports.png';
import { useKeycloak } from '@react-keycloak/web';
import Banner from '../../DefaultComponent/appComponent/Banner';
import moment from 'moment';
import AuthorizedFunction from "../../PrivateRoute/AuthorizedFunction";
import { getreports } from "../../../services/ApiCaller/content/lesson";

export default function StudentAttendenceReport() {
  //view modal
  const navigate = useNavigate();
  const [singleTest, setSingleTest] = useState({});
  const [allLabels, setAllLabels] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  //confirmation
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  //SHOW ERROR MSGS
  const [errorMsg, setErrorMsg] = useState("");
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const diapatch = useDispatch();

  const [unitList, setUintList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  //INTERVENTIONS
  const [interventionName, setInterventionName] = useState("");
  const [interventionId, setInterventionId] = useState("");
  const [interventionDataId, setInterventionDataid] = useState("");
  const [allInterventions, setAllInterventions] = useState([]);
  const [interventionLevel, setInterventionLevel] = useState("");

  const [date, setDate] = useState(new Date());
  const [rowData, setRowData] = useState([]);
  const { keycloak } = useKeycloak();

  const getUsername = () => {
    // console.log("nnn", keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access)
    return keycloak.authenticated && keycloak.tokenParsed  &&( `${keycloak.tokenParsed.given_name}${keycloak?.tokenParsed?.family_name ? " "+keycloak?.tokenParsed?.family_name:''}` )
  
  }

  useEffect(() => {
    fetchUrl();
  }, []);
  const fetchList = () => {
    setRowData([]);
    const fDate = moment(fromDate).format("DD-MM-YYYY")
    const tDate = moment(toDate).format("DD-MM-YYYY")
    const response = getreports("student-attendance-report",getUsername(),fDate,tDate);
    response.then(
      (success) => {
        setLoading(false);
        setRowData(success);
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error);
        // setShowpopup(true);
        // setPopup(true);
        setRowData([]);
      }
    );
  };
  useEffect(() => {
    fetchUrl();
    fetchList();
  }, [fromDate,toDate]);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };
  const headerHeight = 55;

  const [columnDefs] = useState([
    { headerName: "Sr. No.", valueGetter: "node.rowIndex + 1", maxWidth: 120 },
    { headerName: "Curriculum", field: "interventionName", maxWidth: 200 },
    // { headerName: "Curriculum ID", field: "interventionId" },
    { headerName: "School Name", field: "schoolName" },
    { headerName: "School ID", field: "schoolId" },
    { headerName: "Student Name", field: "studentName" },
    { headerName: "Session Name", field: "sessionName" },
    // { headerName: "Session ID", field: "sessionId" },
    // { headerName: "Date of Session", field: "sessionOnDate" },
    {
      headerName: "Date of Session", field: "sessionOnDate",  cellRenderer: (params) => {
        return moment(params.data.sessionOnDate).format('DD-MM-YYYY')
      }
    },
    { headerName: "Time of the Session", field: "timeOfSession" },
    // { headerName: "Online", field: "onlineORoffline" },
    { headerName: "Attendance", field: "absentORpresent" }
    // { headerName: "Marks Scored", field: "marksScored" },
    // { headerName: "Total Marks", field: "totalMarks" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
    };
  }, []);
  const gridRef = useRef();
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 20,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
  };

  return (
    <>
      <Header />
      <div className={loading ? "app backwhite" : "app"}>
        <Sidebar url={currentUrl} />
        {!loading ? (
          <>
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Student Attendance Report</div>
                </div>
              </div>

              {/* <div className="pagetitle"></div> */}
              {/* main page  */}
              <div className='mainpage' > 
                    <div class="page_main">
                        <div class="page_inner reports_main">
                        {/* <p class="h5 mt-3 mb-4 report_table_title">Student Attendence Report</p> */}
                        <div class="page_inner">
                        <Banner img = {bannerImg} styleCss = {'cetf_banner'}/>
                            <div class="certificate_list_outer">
                                {/* <CertificateList cerImage = {demoCert}/> */}
                            </div>
                        </div>
                        <div className="row justify-content-center ">
                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            From Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="Select Date"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="dd-MM-yyyy"
                            // minDate={new Date()}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>

                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            To Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="To Date"
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>

                      </div>
                        <div style={{height: "655px"}} className="ag-theme-alpine">
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    headerHeight={headerHeight}
                                    pagination={true}
                                    defaultColDef={defaultColDef}
                                    paginationPageSize={10}
                                    suppressColumnMoveAnimation={false}
                                    >
                                </AgGridReact>
                                {/* <AgGridReact
                        rowData={rowData}
                        paginationPageSize={10}
                        ref={gridRef} // Ref for accessing Grid's API
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        // rowSelection={"multiple"}
                        rowGroupPanelShow={"always"}
                        pivotPanelShow={"always"}
                        enableRangeSelection={true}
                        pagination={true}
                      ></AgGridReact> */}
                            </div>
                        </div>
                    </div>
                    </div>
            </main>

            {showSuccessMsg ? (
              <SuccessMsg
                msg={successMsg}
                setTrigger={setShowSuccessMsg}
                trigger={triggerSuccess}
              />
            ) : (
              ""
            )}
            {showPopup ? (
              <ErrorMsg
                errorStatus={errorObj}
                setTrigger={setShowpopup}
                trigger={popup}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
