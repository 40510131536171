import { useEffect, useState } from 'react';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
import { ENV } from './../../../util/env';
import { calendarfilter } from '../../../services/ApiCaller/calenddar/calenderapi';
export default function Notification() {
  const [data, setData] = useState([]);
  const [listening, setListening] = useState(false);
    const [userListid, setUserListid] = useState();
  let eventSource = undefined;
  
  const { keycloak } = useKeycloak();
  useEffect(() => {
    // fetchuserList()
  }, [])
  // console.log("data to be submitted...", content);
  // console.log(selectUser)
  //VIEW USER
  const fetchcalendarList =async () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
      const result =await getUserByUserId(filterdata)
    const Date = moment().format("MM-yyyy");
    console.log(Date);
    const Data = JSON.stringify({
      "interventionId":"",
      "batchId": "",
      "schoolId": "",
      "interventionLevelId":"",
      "statuses": [],
      "inmonth":Date
    })
    console.log(Data,"testdata");
    const response =await calendarfilter(result.id,Data);
  setData(response)
    
  };
  useEffect(() => {
    if (!listening) {
      

      fetchcalendarList()
      // response.then((success) => {
      //   console.log("success", success)
      //   eventSource = new EventSource(ENV.NOTIFICATION_URL+"notification/inapp-notification/"+success.id);
      //   console.log("eventSourceeventSourceeventSource", eventSource)
      //   eventSource.onopen = (event) => {
      //     console.log("connection opened")
      //     setData(old => [event.data, ...old])
      //   }
      //   eventSource.onmessage = (event) => {
      //     console.log("result", event.data);
      //     setData(old => [event.data, ...old])
      //   }
      //   eventSource.onerror = (event) => {
      //     console.log(event.target.readyState)
      //     if (event.target.readyState === EventSource.CLOSED) {
      //       console.log('eventsource closed (' + event.target.readyState + ')')
      //     }
      //     eventSource.close();
      //   }
      //   setListening(true); // setUserListid(success)
        
      
      // }, error => {
  
      // })
    
    }
    return () => {
      eventSource?.close();
      console.log("eventsource closed")
    }
  }, [])


  return (
    <div>
      <header className="App-header">
{console.log(data,"datttttt")}
      {data?.map(d =>
          <section key={d}>
            
            <div className="square_box box_three" />
            <div className="square_box box_four" />
            
            <div className="container mb-4">
              <div className="row">
                <div className="col-sm-12">
                  {/* <div className="alert fade alert-simple alert-success alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show"> */}
                    {/* <i className="start-icon far fa-check-circle faa-tada animated" /> */}
                    <strong className="font__weight-semibold breaklinepara">   {d.upcomingAssessmentName ?`Assessment Name :${d.upcomingAssessmentName}`:''} {d.upcomingSessionName ?`Session name :${d.upcomingSessionName},`:''} {d.upcomingAssessmentDateTime ? `Assessment DateTime: ${d.upcomingAssessmentDateTime}`:''}  {d.upcomingSessionDateTime ?`Session DateTime :${d.upcomingSessionDateTime}`:''}</strong>
                  {/* </div> */}
                  
                </div>
              </div>
            </div>
          </section>
          
        )}
      </header>
    </div>
  )
}


