import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';

const CourseStatus = (props) => {
    const {currentStatus} = props
    return(
        <React.Fragment>
            <div className='pregress_main'>
                <ProgressBar now={currentStatus} />
            </div>
        </React.Fragment>
    ) 
}

export default CourseStatus;
