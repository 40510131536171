import axios from "axios";

  // export function leaderDownloadfile(data,filename) {
  //   return  new Promise((resolve,reject)=>{ 
  //    axios.get(`files/files/download/${data}`,
  //    { responseType: 'blob' })
  //      .then(function (response) {
  //       let d = new Date();
  //        resolve(response.data);
  //        console.log("header",response)
  //        const url = window.URL.createObjectURL(new Blob([response.data]));
  //        console.log("url",url)
  //    const link = document.createElement('a');
  //    link.href = url;
  //    link.setAttribute('download', filename); //or any other extension
  //    document.body.appendChild(link);
  //    console.log("link",link)
  //    link.click();
  //      })
  //      .catch(function (error) {
  //        reject(error);
         
  //        // console.log('Error on Authentication');
  //      });
  //    });
  // }
  // http://13.233.166.196:10001/files/files/download/base64//videos/731346557
  export function leaderDownloadfile(data) {
    return  new Promise((resolve,reject)=>{ 
      axios.get(`files/files/download/base64/${data}`)
        .then(function (response) {
          // saveByteArray("Sample Report", bytes);
          resolve(response);
               
                // return getFile;
        })
        .catch(function (error) {
          reject(error);
        });
      });
   }
   export function pptDownLoad(data ,name) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`files/files/download/${data}`,
     { responseType: 'blob' })
       .then(function (response) {
        let d = new Date();
         resolve(response.data);
         console.log("header",response)
         const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', name); //or any other extension
     document.body.appendChild(link);
     link.click();
       })
       .catch(function (error) {
         reject(error);
         
         // console.log('Error on Authentication');
       });
     });
  }