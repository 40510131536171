

import { useKeycloak } from '@react-keycloak/web';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { fetchInterventionLabel, interventionsview } from "../../../services/ApiCaller/admin/intervention";
import { batchListInStatus, calendarfilter1 } from "../../../services/ApiCaller/calenddar/calenderapi";
import { getAllSchoolMasterMasterApi } from '../../../services/ApiCaller/master/masterapi';
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
// import icon from "../../../template/img/footer-logo.svg";
import icon from "../../layout/logo.png";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from '../../DefaultComponent/Loader';
import SuccessMsg from "../../DefaultComponent/SuccessMsg";
import Header from "../../layout/Header";
import Sidebar from '../../layout/Sidebar';
import './sessionList.scss';
import DatePicker from "react-datepicker";


export default function SessionList() {
  //ESSENTIONAL CONFIGURATION
  const { keycloak } = useKeycloak();
  const gridRef = useRef();
  const navigate = useNavigate();
  
  const {
    formState: { errors },
    control
  } = useForm();
  
 
  //AG - GRID 
  const [columnDefs] = useState([
    { headerName: 'School Name', field: 'schoolName' },
    { headerName: 'Curriculum', field: 'interventionName',  },
    { headerName: 'Curriculum Level', field: 'interventionLevelName' },
    { headerName: 'Batch Name', field: 'batchName'},
    // { headerName: 'Unit', field: "", },
    // { headerName: 'Lesson', field: ""},
    { headerName: 'Status', field: 'status'},
    { headerName: 'Teacher First Name', field: 'teacherFirstName' },
    { headerName: 'Teacher Last Name', field: 'teacherLastName' },
    { headerName: 'Session Name', field: "scheduleTitle", },
    {
      headerName: 'Session Start Date', field: "startTime",
      cellRenderer: (params) => {
          { console.log(params) }
          return moment(params.data.startTime).format('DD-MM-YYYY')
          }
  },
    { headerName: 'Session Start Date', field: "startTime"},
    { headerName: 'Session End Date', field: "endTime"},
    { headerName: 'Session Start Time', field: "startTime"},
    { headerName: 'Session End Time', field: "endTime"},
    {
      headerName: 'Update Status', cellRendererFramework: (params) =>
        <div>
          <div className="btn-group  d-flex justify-content-center" >
            <button type="button" className="btn btnedit"
              onClick={() => editSession(params.data)}
            >
              <i className="fa fa-edit" aria-hidden="true"></i></button>
          </div>
        </div>
    }
  ]);
   
  const defaultColDef = useMemo(() => {
    return {
      editable: false, wrapText: true, autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
    };
  }, []);
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);



  //ESSENTIONAL STATES
  const [currentUrl, setCurrentUrl] = useState("")
  const [loading, setLoading] = useState(false)
  //SCHOOL FILTER
  const [allSchools, setAllSchools] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  //INTERVENTIONS
  const [allInterventions, setAllInterventions] = useState([]);
  const [allLabels,setAllLabels]=useState([])
  const [interventionLevel, setInterventionLevel] = useState('');
  const [interventionId, setInterventionId] = useState("");
 //BATCH
 const [allBatch, setAllBatch] = useState([]);
 const [batchId,setBatcId]=useState('')
  //SHOW ERROR MSGS
  const [errorObj, setErrorObj] = useState({})
  const [showPopup, setShowpopup] = useState(false)
  const [popup, setPopup] = useState(false)
  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("")
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [triggerSuccess, setTriggerSuccess] = useState(false)
  //Uset Details
  const [userlistid,setUserListid]=useState("")
  const [sessionData, setSessionData] = useState([]);
  const [getbatchdata, setGetbatchdata] = useState();
  const [month, setMonth] = useState(new Date());

////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    fetchUrl()
    fetchInterventionName()
    fetchSchoolName();
    fetchModerateUserList();
    fetchbatchListName();
  }, [interventionId, schoolId , interventionLevel ,batchId,month])
  const fetchUrl = () => {
    let urlElements = window.location.href.split('/');
    let urlElelement = (urlElements[3])
    setCurrentUrl(`/${urlElelement}`)
  }
  //FETCH ALL INTERVENTIONS AND MAP IT TO DROPDOWN BOX
  const fetchInterventionName = () => {
    setLoading(true)
    setAllInterventions([])
    const response = interventionsview();
    response.then((success) => {
      setLoading(false)
      Object.values(success).map((item) => {
        let single = {
          value: item.id,
          label: item.interventionName,
        };
        setAllInterventions((prevdata) => [...prevdata, single]);
      });
    }, error => {
      setLoading(false)
    });
  };
//FETCH INTERVENTION LABEL BASED ON INTERVENTION
const fetchLables = (interId) =>{
  setAllLabels([])
 const resp = fetchInterventionLabel(interId)
 resp.then((success)=>{
  Object.values(success).map((item) => {
    let single = {
      value: item.id,
      label: item.interventionLevels,
    };
    setAllLabels((prevdata) => [...prevdata, single]);
  });
 })
}

  //FETCH ALL SCHOOLS AND MAP IT TO DROPDOWN BOX
  const fetchSchoolName = () => {
    setAllSchools([])
    setLoading(true)
    const response = getAllSchoolMasterMasterApi();
    response.then((success) => {
      setLoading(false)
      Object.values(success).map((item) => {
        let singleItem = {
          label: item.schoolName,
          value: item.schoolName,
          schoolId: item.schoolId,
        };
        setAllSchools((prevData) => [...prevData, singleItem]);
      });
    },error=>{
      setLoading(false)
    });
  };
  //FETCH ALL BATCH  AND MAP IT TO DROPDOWN BOX
  const fetchbatchListName = () => {
    setAllBatch([])
    const batchStatus = 'Approved';
    const response = batchListInStatus(batchStatus);
    response.then((success) => {
      // setSessionData(success)
    setLoading(false)
    Object.values(success).map((item) => {
      let singleItem = {
        label: item.batchName,
        value: item.id,
      };
      setAllBatch((prevData) => [...prevData, singleItem]);
    });
    },error=>{
    });
  };
 //VIEW MODERATE USER LIST
 const fetchModerateUserList = () => {
  
  let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
  const response = getUserByUserId(filterdata)
  response.then((success) => {
     setUserListid(success.id)
    fetchcalendarList(success.id)
  }, error => {

  })
}
//main filteri
const fetchcalendarList = (userlistid) => {
  setLoading(true);
  const Date = moment(month).format("MM-yyyy");
  console.log(Date);
  const Data = JSON.stringify({
    "interventionId":interventionId,
    "batchId": batchId,
    "schoolId": schoolId,
    "interventionLevelId":interventionLevel ,
    "statuses": [],
    "inmonth":Date
  })
  console.log(Data);
  const response = calendarfilter1(userlistid,Data);

  response.then(
    (success) => {
      setLoading(true);
      console.log(success,"suceosk")
      // const filteredDates = success.filter(success => new Date(success.date) - new Date > 0);
      setSessionData(success)
      
      setLoading(false);
    },
    (error) => {
      setErrorObj(error);
      setShowpopup(true);
      setPopup(true);
      setLoading(false);
    }
  );
};
   
  //UPDATE/EDIT
    const editSession = (data) => {
      navigate(`/sessionstatusupdateedit/${data.id}`)
    };
  //SELECT CUSTOM STYLE
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#111111" : "",
        backgroundColor: state.isSelected ? "#F4F4F4" : "",
        padding: 15,
        '&:hover': {
          // backgroundColor: "#003975d9"
          backgroundColor: "#F4F4F4"
        }
      }),
      control: (base, state) => ({
        ...base,
        borderTop: '0',
        borderLeft: '0',
        borderRight: '0',
        borderBottom: '1px solid black',
        boxShadow: 'none',
        borderRadius: "0",
        '&:hover': {
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '1px solid black',
          borderRadius: "0",
        }
      })
    };

  return (
    <>
      
          <Header />
          <div className=' app'>
            <Sidebar url={currentUrl} />
           
       <main>
       {!loading ?
       <>
              <div className='row pageheader justify-content-between'>
                <div className='col'>
                  <div className='pagetitle'>
                          Session List
                  </div>
                </div>
              </div>
              {/* <div className='pagetitle'>
              
              </div> */}
              <div className='mainpage'>
                <div className="App">
                  <div className="col d-flex justify-content-end">
                  </div>
                  <div className="card">
                    <div className="card-body cardpositions">
                      <div className="row justify-content-center ">
                        {/* FILTER BY SCHOOL */}
                        {/* <div className="form-group  formgroup col-md-3 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select School
                          </label>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value, name, ref } }) => {
                              const currentSelection = allSchools.find(
                                (c) => c.value === value
                              );
                              const handleSchools = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setSchoolId(selectedOption.schoolId);
                                }else {
                                  str = null;
                                  setSchoolId(str)
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allSchools}
                                  onChange={handleSchools}
                                />
                              );
                            }}
                            name="school"
                          />
                        </div> */}
                         {/* INTERVENTION FILTER */}
                        {/* <div className="form-group formgroup col-md-6 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idSelectIntervention"
                            className="labelheading " >
                            Select Intervention
                          </label>
                          <Controller
                            control={control}
                            render={({ field: { onChange, value, name, ref } }) => {
                              const currentSelection = allInterventions.find(
                                (c) => c.value === value
                              );
                              const handleInterventions = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setAllLabels([])
                                  setInterventionId(selectedOption.value)
                                  fetchLables(selectedOption.value)
                                }else {
                                  str = null;
                                  setInterventionId(str)
                                  fetchLables(str)
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allInterventions}
                                  onChange={handleInterventions}
                                />
                              );
                            }}
                            name="interventionName"
                          />
                        </div>
                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select Intervention Level
                          </label> */}
                          {/* role */}
                          {/* <Controller
                            control={control}
                            render={({ field: { onChange, value, name, ref } }) => {
                              const currentSelection = allLabels.find(
                                (c) => c.value === value
                              );
                              const handleInterventionLevel = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  console.log(selectedOption.value)
                                  setInterventionLevel(selectedOption.value);
                                }else {
                                  str = null;
                                  setInterventionLevel(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allLabels}
                                  onChange={handleInterventionLevel}
                                />
                              );
                            }}
                            name="InterventionLevel"
                          />
                        </div> */}
                       {/* FILTER BATCH */}
                        {/* <div className="form-group formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12"> */}
                          {/* <label
                            htmlFor="idSelectIntervention"
                            className="labelheading "
                          >
                            Select Batch
                          </label> */}
                          {/* type */}
                          {/* <Controller
                            control={control}
                            render={({ field: { onChange, value, name, ref } }) => {
                              const currentSelection = allBatch.find(
                                (c) => c.value === value
                              ); */}
                              {/* //HANDLE USER TYPE HANDLER
                              const handleBatch = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setBatcId(selectedOption.value)
                                } else {
                                  str = null;
                                  setBatcId(str)
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allBatch}
                                  onChange={handleBatch}
                                />
                              );
                            }}
                            name="Batch"
                          /> */}
                        {/* </div> */}
                        
    
        <div className="form-group main-crd  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
          <label htmlFor="idRole" className="labelheading">
            Select Month <span className="errormark"> *</span>
          </label>
          <DatePicker
            className="form-control "
            selected={month}
            onChange={(date) => setMonth(date)}
            dateFormat="MM-yyyy"
            showMonthYearPicker
          />
        </div>
      
 
                      </div>
                    </div>
                  </div>
                  {!loading ? <><div className='row'>
{
                          sessionData.map((item, index) => {
                          
                        // setGetbatchdata(item.batch)
                        // {item.batch != null ? item.batch: "The meeting link is not available"}
                            return (
                              <div className='col-12 col-sm-12 col-ml-6 col-lg-4 col-xl-4 col-xxl-4 mt-3 mb-2'>
                              {/* Hover Demo*/}
                          <div className="column">
                            
                            {/* Post*/}
                            <div className="post-module hover">
                              {/* Thumbnail*/}
                              <div className="thumbnail">
                                <div className="overlay-ribbon">
                                <div className="ribbon-content">
                                  <h3>{index+1}</h3>
                                </div>
                              </div>
                              {/* <div className="index">{index+1}</div> */}
                                <div className="date">
                                
                                  {/* <div className="day">{moment(item.date).format('DD')}</div>
                                  <div className="month">{moment(item.date).format('MMM')}</div>
                                  <div className="month">{moment(item.startTime).format('hh:mm A')}</div> */}
                                </div><img src={icon} />
                          
                              </div>
                              {/* Post Content*/}
                              <div className="post-content">
                                {/* <div className="category">{index+1}</div> */}
                                <div className="date">
                                  <div className="category">{moment(item.date).format('DD')} {moment(item.date).format('MMM')}</div>
                                  </div>
                           
                                <h1 className="title">{item.scheduleTitle}</h1>
                                <div className="time-limit">{moment(item.startTime).format('hh:mm A')} to {moment(item.endTime).format('hh:mm A')}</div>

                                {/* <h2 className="sub_title">Batch Name : {item.batchName ? item.batchName : "N/A"}</h2> */}

                                <p className="description">
                                  {console.log(item.teacherFirstName,"item")}
                                  Teacher :  {item.teacherFirstName ? item.teacherFirstName  : "N/A"}   {item.teacherLastName ? item.teacherLastName  : "N/A"}<br></br>
                                  
                                
                                {/* Session name: {{item.interventionName ? item.interventionName :"N/A"} }  */}
                                Curriculum Name : {item.interventionName ? item.interventionName :"N/A"}<br></br>
                                <span className='text'>Batch</span> : {item.batchName ? item.batchName : "N/A"}
                                
                                </p>
                                {item.status === "Cancelled" ?
      <p className = "description AppointmentTooltiptext title">This session has been cancelled</p> :
      <>
                                <p className="description AppointmentTooltiptext">
                                {item.link != null ?        <a href={item.link} className={item.link != null &&!item?.joinLinkDisable? "btn lmsbtn m-0 " : "btn m-0 disabled"} target={'_blank'}> {item.link != null ? "Join class": ""}</a>:null} </p>
                                <p className = " status">{item?.offline === true ? "Online" : "Offline"}<span className = {item.offline === true ? "dot-online" : "dot-offline" }></span> </p>
                                </>
                                }
                                {/* <div className="post-meta"><span className="timestamp"><i className="fas fa-clock" /> {moment(item.startTime).format('HH:MM A')}</span><span className="comments"><i className="fas fa-calendar" /> {moment(item.startTime).format('DD-MMMM-YYYY')}</span></div> */}
                              </div>
                            </div>
                          </div>
                          </div>
                            )
                          })
                        }

</div></>
              :
          <Loader />
        }

           



                </div>
              </div>
              </>
              :
          <Loader />
        }
            </main>
            
          </div>
         
          {showPopup ?
            <ErrorMsg
              errorStatus={errorObj}
              setTrigger={setShowpopup}
              trigger={popup}
            /> :
            ''}
          {
            showSuccessMsg ?
              <SuccessMsg
                msg={successMsg}
                setTrigger={setShowSuccessMsg}
                trigger={triggerSuccess}
              />
              : ''
          }
        </>
       
  )
}





// import { useEffect, useState } from 'react';

// export default function SessionList() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false)
//   const [currentUrl, setCurrentUrl] = useState("")

//   const [interventionName, setInterventionName] = useState("");
//   const [interventionId, setInterventionId] = useState("");
//   const [interventionDataId, setInterventionDataid] = useState("");
//   const [selectedIntervention, setSelectedIntervention] = useState('')
//   const [allInterventions, setAllInterventions] = useState([]);
//   const [unit, setUnit] = useState([]);
//   const [lesson, setLesson] = useState([]);
//   const [school, setSchool] = useState([]);
//   const [batch, setBatch] = useState([]);
//   const [level, setLevel] = useState([]);

//   useEffect(() => {
//     fetchUrl();
//   }, [])
//   const fetchUrl = () => {
//     let urlElements = window.location.href.split('/');
//     let urlElelement = (urlElements[3])
//     setCurrentUrl(`/${urlElelement}`)
//   }

//   const customStyles = {
//     option: (provided, state) => ({
//       ...provided,
//       color: state.isSelected ? '#ffffff' : '',
//       backgroundColor: state.isSelected ? '#003366' : '',
//       padding: 20,
//     }),
//     control: (base, state) => ({
//       ...base,
//       borderTop: '0',
//       borderLeft: '0',
//       borderRight: '0',
//       borderBottom: '1px solid black',
//       boxShadow: 'none',
//       borderRadius: "0",
//       '&:hover': {
//         borderTop: '0',
//         borderLeft: '0',
//         borderRight: '0',
//         borderBottom: '1px solid black',
//         borderRadius: "0",
//       }
//     })
//   }
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//     setValue,
//     trigger,
//     control
//   } = useForm();

//   const handleSession = (id) => {
//     navigate(`/Materials`)
//   }

//   return (
//     <>
//       <Header />
//       <div className=' app' >
//         {/* Sidebar */}
//         < Sidebar url={currentUrl} />
//         {/* main comp */}
//         <main >
//           {!loading ?
//             <>
//               {/* page title */}
//               <div className='row pageheader justify-content-between'>
//                 <div className='col'>
//                   <div className='pagetitle'>
//                     Hi ,
//                   </div>
//                 </div>
//                 {/* <div className='col d-flex justify-content-end'> <button
//                     type="button"
//                     className="btn lmsbtn btn-sm"
//                     onClick={() => setInterventionsShow(true)}
//                   >
//                     Add Intervention
//                   </button>
//                   </div> */}
//               </div>
//               {/* main page  */}
//               <div className='mainpage'>
//                 <div className="App">
//                   <div className="col d-flex justify-content-end">
//                   </div>
//                   <div calssName="card">
//                     <div calssName="card-body">
//                       <div className="row justify-content-center ">
//                         <div className="form-group formgroup col-md-6 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
//                           <label
//                             htmlFor="idSelectIntervention"
//                             className="labelheading " >
//                             Select Intervention
//                           </label>

//                           <Controller
//                             control={control}
//                             render={({ field: { onChange, value, name, ref } }) => {
//                               const currentSelection = allInterventions.find(
//                                 (c) => c.value === value
//                               );
//                               const handleInterventions = (selectedOption) => {
//                                 onChange(selectedOption?.value);
//                                 let str;
//                                 if (selectedOption != null) {
//                                   setInterventionName(selectedOption.label);
//                                   setInterventionId(selectedOption.value)
//                                   setInterventionDataid(selectedOption.id)
//                                 }
//                                 else {
//                                   str = null;
//                                   setInterventionName(str);
//                                   setInterventionId(str)
//                                   setInterventionDataid(str)
//                                 }
//                               };
//                               return (
//                                 <Select
//                                   isClearable
//                                   styles={customStyles}
//                                   className="Selectontrol"
//                                   menuColor="red"
//                                   inputRef={ref}
//                                   value={currentSelection}
//                                   name={name}
//                                   options={allInterventions}
//                                   onChange={handleInterventions}
//                                 />
//                               );
//                             }}
//                             name="interventionName"

//                           />

//                         </div>
//                         <div className="form-group  formgroup col-md-3 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
//                           <label htmlFor="idRole" className="labelheading">
//                             Select Intervention Level
//                           </label>
//                           <Controller
//                             control={control}
//                             render={({ field: { onChange, value, name, ref } }) => {
//                               const currentSelection = level.find(
//                                 (c) => c.value === value
//                               );

//                               const handleLevel = (selectedOption) => {
//                                 onChange(selectedOption?.value);
//                                 let str;
//                                 setLevel([])
//                                 if (selectedOption != null) {
//                                   setLevel(selectedOption.value)
//                                 }
//                                 else {
//                                   str = null;
//                                   setLevel(str);
//                                 }
//                               };

//                               return (
//                                 <Select
//                                   isClearable
//                                   styles={customStyles}
//                                   className="Selectontrol"
//                                   menuColor="red"
//                                   inputRef={ref}

//                                   value={currentSelection}
//                                   name={name}
//                                   options={level}
//                                   onChange={handleLevel}
//                                 />
//                               );
//                             }}
//                             name="level"

//                           />

//                         </div>
//                         <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
//                           <label htmlFor="idRole" className="labelheading">
//                             Select Batch
//                           </label>
//                           {/* role */}
//                           <Controller
//                             control={control}
//                             render={({ field: { onChange, value, name, ref } }) => {
//                               const currentSelection = batch.find(
//                                 (c) => c.value === value
//                               );

//                               const handleBatch = (selectedOption) => {
//                                 onChange(selectedOption?.value);
//                                 let str;
//                                 if (selectedOption != null) {
//                                   console.log(selectedOption.value)
//                                   setBatch(selectedOption.value);
//                                 }
//                                 else {
//                                   str = null;
//                                   setBatch(str);
//                                 }
//                               };

//                               return (
//                                 <Select
//                                   isClearable
//                                   styles={customStyles}
//                                   className="Selectontrol"
//                                   menuColor="red"
//                                   inputRef={ref}
//                                   value={currentSelection}
//                                   name={name}
//                                   options={batch}
//                                   onChange={handleBatch}
//                                 />
//                               );
//                             }}
//                             name="Batch"

//                           />

//                         </div>
//                         <div className="form-group formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
//                           <label
//                             htmlFor="idSelectIntervention"
//                             className="labelheading "
//                           >
//                             Select School
//                           </label>
//                           {/* type */}
//                           <Controller
//                             control={control}
//                             render={({ field: { onChange, value, name, ref } }) => {
//                               const currentSelection = school.find(
//                                 (c) => c.value === value
//                               );
//                               //HANDLE USER TYPE HANDLER
//                               const handleSchool = (selectedOption) => {
//                                 onChange(selectedOption?.value);
//                                 let str;
//                                 if (selectedOption != null) {
//                                   setSchool(selectedOption.value);
//                                 } else {
//                                   str = null;
//                                   setSchool(str);
//                                 }
//                               };

//                               return (
//                                 <Select
//                                   isClearable
//                                   styles={customStyles}
//                                   className="Selectontrol"
//                                   menuColor="red"
//                                   inputRef={ref}
//                                   value={currentSelection}
//                                   name={name}
//                                   options={school}
//                                   onChange={handleSchool}
//                                 />
//                               );
//                             }}
//                             name="school"

//                           />

//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 <div className='row'>
//                    {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
                           

//                           </div> */}
//                         {/* </div>
//                       </div> */}

//                     {/* </div>
//                   </div> */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div> */}

//                     {/* </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div>  */}
//                   {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 col-sm-12 my-3 '>
//                     <div class="card cardbody">
//                       <div className='row'>
//                         <div className='col-3 pe-0'>
//                           <div className='cardiconbox'>
//                             <img src={Assignmentsicon} />
//                           </div>
//                         </div>
//                         <div className='col-9 ps-0'>
//                           <div class="card-body p-0">
//                             <div className='row'>
//                               <div className='col-12'><p class="card-title cardtitle mt-2">Full Name</p></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Batch  </span></div>
//                               <div className='col-8 px-0'> </div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Teacher  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Unit  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Lesson  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Status  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Date  </span></div>
//                               <div className='col-8 px-0'></div>
//                               <div className='col-4 pe-0'><span class="card-title subcardtitle">Session Time  </span></div>
//                               <div className='col-8 px-0'></div>


//                               <div className='col-12 Sessionbtn'>
//                                 <button type="button" class="btn lmsbtn disabled">Primary</button>
//                               </div>

//                             </div>
//                             {/* {user.name && user.lastName ? <>
//                           <p class="card-text cardtext">{user.name} {user.lastName}</p></> :
//                           <> <h4 class="nodata_title">No Data Found </h4> </>} */}

//                           {/* </div>
//                         </div>
//                       </div>

//                     </div>
//                   </div> */}
//                    <div className="row">
//                     {/*ADD CLASSES HERE d-flex align-items-stretch*/}
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>1</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>2</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>3</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>4</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>5</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>6</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                             <h3>7</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
//                     onClick={() => handleSession()}
//                      >
//                       <div className="card Materialsbodys shadow-lg">

//                         <div class="overlay-ribbon">
//                           <div class="ribbon-content">
//                           <h3>8</h3>
//                           </div>
//                         </div>
//                         {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
//                         <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
//                         <div className="card-body d-flex flex-column">
//                         <h5 className="card-title belonghover">Session Name</h5>
//                         <p className="card-text1 belonghover mb-4">Batch Name</p>
//                         <p className="card-text1 belonghover mb-4">Teacher Name</p>
                         
//                           <div className = "row">
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Unit</p>
//                             </div>
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Lesson</p>
//                             </div>
                           
//                           </div>
                          
//                           <div className = "row">
//                             <div className = "col-lg-6 d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Date</p>
//                             </div>
//                             <div className = "col-lg-6  d-flex align-items-stretch ">
//                             <p className="card-text1 belonghover mb-4">Session Time</p>
//                             </div>
//                             </div>
                          
//                             <p className="card-title belonghover mb-4">Status</p>  
//                         </div>
//                       </div>
//                     </div>
//                     </div>
                   
//                   </div>
//                 </div> 
//               </div>
//             </>
//             :
//             <Loader />
//           }
//         </main>
//       </div>
//     </>
//   )
// }
