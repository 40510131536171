
import axios from '../../axiosconfig';

// content Craetion
export function contentCreation(data) {
   return  new Promise((resolve,reject)=>{ 
    axios.post('content/api/v1/lms-content/content/create',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
    });
}

//content filter
export function contentFilter(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('content/api/v1/lms-content/content/filter',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}
export function contentFilterUnit(id) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`content/api/v1/lms-content/lesson/student/getByUnit/${id}`,{},{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

//filter content by status
export function filtercontentByStatus(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.post('content/api/v1/lms-content/content/getByStatuses',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

//fetch content List
export function contentList() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('content/api/v1/lms-content/content/getAll')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

  //get content in Id
  export function contentbyId(inId) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`content/api/v1/lms-content/content/getById/${inId}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

      //content delete 
      export function contentDelete(inId) {
        return  new Promise((resolve,reject)=>{ 
         axios.delete(`content/api/v1/lms-content/content/delete/${inId}`)
           .then(function (response) {
             resolve(response.data);
           })
           .catch(function (error) {
             reject(error);
           });
         });
      }


     //content Edit
export function contentEdit(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('content/api/v1/lms-content/content/edit',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }


//content Send for approval
export function contentSendForApproval(id,userid) {
    return  new Promise((resolve,reject)=>{ 
     axios.put(`content/api/v1/lms-content/content/sendForApproval/${id}/${userid}`,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }


//content Validate
export function contentValidate(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.put('content/api/v1/lms-content/content/validate',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  
  }


//toggle content by id
export function togglecontentById(intId) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`content/api/v1/lms-content/content/toggleStatus/${intId}`,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

