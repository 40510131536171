import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../DefaultComponent/Loader';
import { AssessmentDataByAssessmentId } from '../../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController'
import { Assessmenttime, countAttemptOnContinue } from '../../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController'
import Header from '../../../layout/Header'
import moment from 'moment';
export default function ExamTermsConditions(props) {
  const { ass_id , userId} = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [questionsDetails, setQuestionsDetails] = useState([])
  const [totaltime, setTotaltime] = useState(0);

  useEffect(() => {
    console.log(window.location.search,)
    // fetchUrl();
    fetchAssessmentDetails()
    fetchAssessmentTime();
  }, []);
  const fetchAssessmentTime = () => {
    const resp = Assessmenttime(ass_id)
    resp.then((success) => {
      console.log("ass time", success)
      setTotaltime(success)
      // setTotaltime(success)
    })
  }
  const fetchAssessmentDetails = () => {
    setLoading(true)
    const resp = AssessmentDataByAssessmentId(ass_id)
    resp.then((success) => {
      setLoading(false)
      setQuestionsDetails(success)
    })
  }
  //count attemp is eligible to move or not
  const countAttemptToMove =() =>{
    setLoading(true)
   const resp = countAttemptOnContinue(userId,ass_id,window?.location?.search =="?mock=true" ? "Mock":"Assessment")
   resp.then((success)=>{
    setLoading(false)
    if(window?.location?.search =="?mock=true")
    {
      // navigate(`/onlineMcqExam/${ass_id}/${userId}/${totaltime}?mock=true`)
      navigate(`/onlineMcqExam/${ass_id}/${userId}/${totaltime}?mock=true`, {state: moment().format('YYYY-MM-DD HH:mm:ss')});


    }else{
    // navigate(`/onlineMcqExam/${ass_id}/${userId}/${totaltime}`)
    navigate(`/onlineMcqExam/${ass_id}/${userId}/${totaltime}`, {state: moment().format('YYYY-MM-DD HH:mm:ss')});

    }
   },error=>{
    setLoading(false)
   })
  }
  return (
    <div>
      <Header />
      <div className=" app">
        <main>
          <div className='container-fluid'>
            <div className='container-fluid'><div className='container-fluid'> <div className='container-fluid'>
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Instructions for Examination</div>
                  <ol>
                  <li>There will be {questionsDetails.length} questions for Digital Literacy Assessment.</li>
                  <li>For each question two or more than two possible answers will be given.</li>
                  <li>You have to choose the correct one by clicking the mouse.</li>
                  <li>You can attempt only 2 Times</li>
                  <li>The time limit will be  {totaltime} minutes.</li>
                  <li>
                    The assessment will be undertaken in the presence of an examiner and under his instructions</li>
                  <li>The student may not use his or her textbook, course notes, or receive help from a proctor or any other outside source.</li>
                  <li>Students must not stop the session and then return to it. This is especially
                    important in the online environment where the system will "time-out" and not allow the student or you to reenter the exam site.</li>
                </ol>
                </div>

               

              </div>
              {
                loading?    <Loader />:''
              }
              
              <button className='btn lmsbtn Qlmsbtn me-3 ms-3'
        onClick={() => {
         
          countAttemptToMove()
        }}>Continue</button>
            </div></div>
            </div>


          </div>

        </main>
      </div>
      

    </div>
  )
}
