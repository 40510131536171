
import axios from '../../axiosconfig';

export function calendarfilter(userid,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`admin/api/v1/lms-admin/calendar/upcomingSession/${userid}/filter`,data,{
    headers: {
        //'Access-Control-Allow-Origin':'*',
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
  })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function calendarfilter1(userid,data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`admin/api/v1/lms-admin/calendar/${userid}/filter`,data,{
    headers: {
        //'Access-Control-Allow-Origin':'*',
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
  })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
//Calender By Id
export function calenderById(id) {
  return  new Promise((resolve,reject)=>{ 
    axios.get(`admin/api/v1/lms-admin/calendar/${id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
    });
 }

//batchList in Status
export function batchListInStatus(inStatus) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`batch/api/v1/lms-batch/batch/list/${inStatus}/ui`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}

//get All Gis State Master Master
//state
export function getAllcalendarApi(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/schedule/user/${data}/calendar`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//get All Gis State Master Master New
//state
export function getAllcalendarApis(data,inmonth) {
  return  new Promise((resolve,reject)=>{ 
   axios.post(`admin/api/v1/lms-admin/schedule/light/user/${data}/calendar`,inmonth, {
    headers: {
        //'Access-Control-Allow-Origin':'*',
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
  })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

//Get User Details
export function getuserDetails(userId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`admin/api/v1/lms-admin/student/getStudentDetailsByUserId/${userId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}
export function getUnitByUserId(userId) {
  return  new Promise((resolve,reject)=>{ 
   axios.get(`content/api/v1/lms-content/unit/getUnitByUserId/${userId}`)
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
       // console.log('Error on Authentication');
     });
   });
}

