import React from 'react'
import { useNavigate } from 'react-router-dom';

const Reminder = (props) => {
    const {icon, notificationFor, title, module, time} = props;
    const navigate = useNavigate()
    return(
        <React.Fragment>
            <div className='reminder_section'>
                <div className='reminder_box'>
                    <div className='reminder_left'>
                    <span className={`reminder_icon ${notificationFor}`}>{icon}</span>
                        <div className='reminder_for'>
                            <h5 className='reminder_text'>{title}</h5>
                            <p><span className='reminder_sub_text'>Module {module} Quiz</span> <span><i class="fa-regular fa-clock ms-3 me-1"></i> {time} min ago</span></p>
                        </div>
                    </div>
                    <div className='reminder_right' onClick={() => {navigate("/Course/CourseView/ModuleContentView")}}>
                        <div className='navigate_to'>
                            <span className='navigate_icon'><i class="fa-solid fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default Reminder;