import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userUIRole } from "../../../util/roleName";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from "../../DefaultComponent/Loader";
import SuccessMsg from "../../DefaultComponent/SuccessMsg";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import AuthorizedFunction from "../../PrivateRoute/AuthorizedFunction";

export default function BatchAttendenceReport() {
  //view modal
  const navigate = useNavigate();
  const [singleTest, setSingleTest] = useState({});
  const [allLabels, setAllLabels] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  //confirmation
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  //SHOW ERROR MSGS
  const [errorMsg, setErrorMsg] = useState("");
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const diapatch = useDispatch();

  const [unitList, setUintList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [triggerSuccess, setTriggerSuccess] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  //INTERVENTIONS
  const [interventionName, setInterventionName] = useState("");
  const [interventionId, setInterventionId] = useState("");
  const [interventionDataId, setInterventionDataid] = useState("");
  const [allInterventions, setAllInterventions] = useState([]);
  const [interventionLevel, setInterventionLevel] = useState("");

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setTotDate] = useState(new Date());

  useEffect(() => {
    fetchUrl();
  }, []);

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };

  const [columnDefs] = useState([
    { headerName: "Sr. No.", valueGetter: "node.rowIndex + 1", maxWidth: 120 },
    { headerName: "Curriculum Name", field: "" },
    { headerName: "Curriculum ID", field: "" },
    { headerName: "School Name", field: "" },
    { headerName: "School ID", field: "" },
    { headerName: "Session Name", field: "" },
    { headerName: "Session ID", field: "" },
    { headerName: "Date of Session", field: "" },
    { headerName: "Time of the Session", field: "" },
    { headerName: "Online", field: "" },
    { headerName: "Students Present Percentage", field: "" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
    };
  }, []);
  const gridRef = useRef();
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 20,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
  };

  return (
    <>
      <Header />
      <div className={loading ? "app backwhite" : "app"}>
        <Sidebar url={currentUrl} />
        {!loading ? (
          <>
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Batch Attendance Report</div>
                </div>
              </div>
              {/* <div className="pagetitle"></div> */}
              {/* main page  */}
              <div className="mainpage">
                <div className="App">
                  <div className="col d-flex justify-content-end"></div>
                  <div calssName="card">
                    <div calssName="card-body">
                      <div className="row justify-content-center ">
                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            From Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="From Date"
                            // selected={fromDate}
                            // onChange={date => setFromDate(date)
                            // }
                            dateFormat="dd-MM-yyyy"
                            // minDate={new Date()}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>

                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            To Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="To Date"
                            // selected={toDate}
                            // onChange={date => setToDate(date)
                            // }
                            dateFormat="dd-MM-yyyy"
                            // minDate={new Date()}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between">
                    <div className="col agPagediv">
                      Page Size:
                      <select onChange={onPageSizeChanged} id="page-size">
                        <option value="10" selected={true}>
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col agfilterdiv">
                      {" "}
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search..."
                        className="agfilter"
                        onInput={onFilterTextBoxChanged}
                      />
                    </div>
                  </div>

                  <div className="ag-theme-alpine agtheme">
                    <AgGridReact
                      rowData={unitList}
                      paginationPageSize={10}
                      ref={gridRef} // Ref for accessing Grid's API
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      suppressRowClickSelection={true}
                      groupSelectsChildren={true}
                      rowSelection={"multiple"}
                      rowGroupPanelShow={"always"}
                      pivotPanelShow={"always"}
                      enableRangeSelection={true}
                      pagination={true}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </main>

            {showSuccessMsg ? (
              <SuccessMsg
                msg={successMsg}
                setTrigger={setShowSuccessMsg}
                trigger={triggerSuccess}
              />
            ) : (
              ""
            )}
            {showPopup ? (
              <ErrorMsg
                errorStatus={errorObj}
                setTrigger={setShowpopup}
                trigger={popup}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
