import { useEffect, useState } from "react";


import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from "react-router-dom";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
// import iconbg from "../../../template/img/NewProject.png";
import iconbg from "../../layout/logo.png";
import { filtergetByUserIdUnit } from "../../../services/ApiCaller/content/unit";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import "./Materials.scss";

export default function Materials() {
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [allUnit,setAllUnit]=useState([])
  const [userlistid,setUserListid]=useState()
  const navigate = useNavigate();
  useEffect(() => {
    fetchModerateUserList();
    fetchUrl();
    userlistid&& fetchUnit();
    
  }, [userlistid]);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };
   //VIEW MODERATE USER LIST
 const fetchModerateUserList = () => {
  
  let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
  const response = getUserByUserId(filterdata)
  response.then((success) => {
     setUserListid(success.id)
  }, error => {

  })
}
  const fetchUnit = () => {
    setLoading(true)
    setAllUnit([])
    const Data = JSON.stringify({
      "interventionId": "",
      "interventionLevelId":""
    })
    console.log(Data)
    // const response = filterUnit(Data)
    const response=filtergetByUserIdUnit(userlistid)
    response.then((success) => {
      
      setLoading(false)
      setAllUnit(success)
    }, error => {
      setLoading(false)
      // setErrorObj(error)
      // setShowpopup(true);
      // setPopup(true)
    })
  }



  const handleunit = (item) => {
    console.log("itemmmm",item.id)
    navigate(`/Lesson/${item.id}`)
  }
  // const handleunit = (lessonId) => {
  //   navigate(`/LessonContent/${lessonId}`,
  //     {
  //       // state: id
  //     })
  // };

  return (
    <>
      <Header />
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} />
        {/* main comp */}
        <main className="mainbg">
          {!loading ? (
            <>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Units</div>
                </div>
              </div>
              {/* main page  */}
              <div className="mainpage">
                <div className="App">
                  <div className="row">
                    {/*ADD CLASSES HERE d-flex align-items-stretch*/}
                    {
                      allUnit && allUnit.map((item,index)=>{
                        return(
                          <div className="col-lg-3 mb-5 d-flex align-items-stretch " 
                          onClick={() => handleunit(item)} >
                            <div className="card Materialsbodys shadow-lg p-4">
                              <div className="overlay-ribbon1">
                                <div className="ribbon-content">
                                  <h3>{index+1}</h3>
                                </div>
                              </div>
                              {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
                              <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
                              <div className="card-body d-flex flex-column">
                                <h5 className="card-title belonghover">{item.name}</h5>
                                {/* <p className="card-text1 belonghover mb-4">10 Lesson</p> */}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                   
        
                  </div>

                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </>
  );
}
