import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from "../../../src/features/layout/SideBarContext";

import logo from "./logo.png";
import styles from './DefaultComponent.module.scss';
export default function Header() {
  // import { isAdmin } from '../misc/Helpers'
  const navigate = useNavigate()
  const { keycloak } = useKeycloak();
  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      // props.history.push('/')
      navigate("/")
      keycloak.logout()
      localStorage.removeItem("authenticate")
      localStorage.removeItem("jwt-secret")
      localStorage.removeItem("last_login_time")
    } else {
      keycloak.login()
    }
  }
  useEffect(() => {
    getresource_access()
  }, [])
  const checkAuthenticated = () => {
    if (!keycloak.authenticated) {
      handleLogInOut()
    }
  }
  const { sidebarCollapsed, setSidebarCollapsed } = useSidebar();

  const openSidebar = () => {
    console.log(sidebarCollapsed)
    if(sidebarCollapsed){
  
      setSidebarCollapsed(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setSidebarCollapsed(true);
      localStorage.setItem("sidebar-collapsed", false);
  
    };
  const getUserresource = () => {
    return keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access
  }

  const getUsername = () => {
    console.log("nnn",  keycloak.tokenParsed)
    return keycloak.authenticated && keycloak.tokenParsed  &&( `${keycloak?.tokenParsed?.given_name} ${keycloak?.tokenParsed?.family_name  ?keycloak?.tokenParsed?.family_name:'' }` )

  }
  const getresource_access = () => {

    const resourceaccessname = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access
    // return keycloak.authenticated && keycloak.tokenParsed &&( `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}` )

    if (!resourceaccessname.lms_beneficiary_portal) {
      navigate("/")
      keycloak.logout()
     localStorage.removeItem("authenticate")
      localStorage.removeItem("jwt-secret")
      localStorage.removeItem("last_login_time")
    }


  }
  const getLogInOutText = () => {
    return keycloak.authenticated ? "Logout" : "Login"
  }
  return (

    <>

      <nav className={`navbar navbar-expand-sm navbar-light ${styles.Headernav}`}>

        <div className="container-fluid header_container_fluid">
        <span className='bar' style={{margin:'0 25px'}}> <i class="fa-solid fa-bars" onClick={openSidebar}></i> </span>
          <a className="navbar-brand" href="/admin-app">
            <img src={logo} className="img-fluid w-75" /></a>
          {/* <button className="navbar-toggler" type="button" onClick={openSidebar}data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <a className="navbar-brand" href="/admin-app">
            <img src={logo} className="img-fluid" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          
          <div className="collapse navbar-collapse"  id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">

              </li>

            </ul>
            <span className="navbar-text navbartext">
              <span className={`${styles.getUsername} user-name`}>{getUsername()}</span>
              <button className={`btn  ${styles.profilebtn} profile-btn`} type="button ">   <i className="fa fa-user" aria-hidden="true"></i>
              </button>

              <DropdownButton id="dropdown-basic-button " className={`btn d-inline-block ${styles.dropdownbtn}`} title={<i className="fa fa-cog" aria-hidden="true"></i>}>
                {/* <Dropdown.Item className={`  ${styles.dropdownitme}`} type="button " > <i className="fa fa-key mr-1" aria-hidden="true"></i> change  password </Dropdown.Item> */}
                <Dropdown.Item className={`  ${styles.dropdownitme}`} type="button " onClick={handleLogInOut}> <i className="fa fa-sign-out mr-1" aria-hidden="true"></i> {getLogInOutText()} </Dropdown.Item>
              </DropdownButton>
            </span>
          </div>
        </div>
      </nav>
    </>
  )
}
