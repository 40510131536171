import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { GetSvgIcon } from "../../util/SvgIcons";

import { useSidebar } from "../../../src/features/layout/SideBarContext";

import { Link } from "react-router-dom";
import assessmenticon from "../../template/img/assessment approver.svg";
import certificateIcon from "../../template/img/Component 1.svg"
import book from "../../template/img/study_material.svg";

import Batch_Schedule_Approver from "../../template/img/Group-1.svg";
import Dashboard_icon from "../../template/img/Vector(1).svg";
import Calendar_icon from "../../template/img/Vector2.svg";
import "../layout/layout.scss";

const Sidebar = (props) => {
  // const [Calendar, setCalendar] = useState(false)
  // const [sessionList, setSessionList] = useState(false)
  // const [assignments, setAssignments] = useState(false)
  // const [courses, setCourses] = useState(false)
  // const [dashboard, setDashboard] = useState(false)
  // const [onlineexam, setOnlineexam] = useState(false)
  // const [Materials, setMaterials] = useState(false)
  // const [viewReportCard, setViewReportCard] = useState(false)
  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const [userroles, setUserroles] = useState(["roles"]);
  // useEffect(() => {
  //   if (props.url === "/Dashboard") {
  //     setDashboard(true)
  //   }
  //   if (props.url === "/Courses") {
  //     setCourses(true)
  //   }
  //   if (props.url === "/Materials") {
  //     setMaterials(true)
  //   }
  //   if (props.url === "/Calendar") {
  //     setCalendar(true)
  //   }
  //   if (props.url === "/OnlineExam") {
  //     setOnlineexam(true)
  //   }
  //   if (props.url === "/Assignments") {
  // setAssignments(true)
  //   }
  //   if (props.url === "/SessionList") {
  // setSessionList(true)
  //   }
  //   if (props.url === "/ViewReportCard") {
  //     setViewReportCard(true)
  //   }
  // }, [props.url])
  const { keycloak } = useKeycloak();

  // const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");

  // const [menuCollapse, setMenuCollapse] = useState(
  //   sidebarCollapsed ? false : true
  // );
  // const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");

  

  
  // const [state, setState] = useState({
  //       open: window.matchMedia("(min-width: 479px)").matches || false,
  //     });
    
  //     const ontoggleNav = () => {
  //       setState((prevState) => ({
  //         open: !prevState.open,
  //       }));
  //       // alert(ocalStorage.getItem("sidebar-collapsed"))
  //       if (menuCollapse === true) {
  //         setMenuCollapse(false);
  //         localStorage.removeItem("sidebar-collapsed");
  //       } else {
  //         localStorage.setItem("sidebar-collapsed", true);
  //         setMenuCollapse(true);
  //       }
  //     };
  //     const { open } = state;
  //     const mobile = window.matchMedia("(max-width: 479px)").matches;
    
  //     if (mobile && open) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "unset";
  //     }
  const { sidebarCollapsed, setSidebarCollapsed } = useSidebar();
const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed == null ? false : true
  );
  // //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    // menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    // if (menuCollapse) {
    //   setMenuCollapse(false);
    //   localStorage.setItem("sidebar-collapsed", true);
    //   return;
    // }
    // setMenuCollapse(true);
    // localStorage.removeItem("sidebar-collapsed");
  };
  return (
    <div className={sidebarCollapsed ? "sidenavClose" : "sidenavOpen"}>
<ProSidebar collapsed={sidebarCollapsed}>      <SidebarContent>
        {/* <div className="closemenu" onClick={menuIconClick}> */}
          {/* changing menu collapse icon on click */}
          {/* {menuCollapse ? (
            <>
            <button className="btn sidebar-collapse-btn">
            <i class="fa-solid fa-angle-right icon_size"></i>
            </button>
            </>
          ) : (
            <button className="btn sidebar-collapse-btn">
              <i className="fa-solid fa-angle-left icon_size" />
            </button>
          )}
        </div> */}
        <Menu>
          <MenuItem
            className={props.url === "/Dashboard" || window.location.pathname.includes('Dashboard') ? "active_submenu" : ""}
            icon={<img src={Dashboard_icon}  className="w-75"/>}
          >
            <Link to="/Dashboard" onClick={menuIconClick}> <span style={{color:'#003366'}}>Dashboard</span> </Link>
          </MenuItem>
        </Menu>
        {/* <Menu >
          <MenuItem className={props.url === "/Courses" ? "active_submenu" : ""} icon={<img src={Dashboard_icon} />
          }><Link to="/Courses"  >Courses </Link></MenuItem>
        </Menu> */}

        <Menu>
          <MenuItem
            className={props.url === "/Calendarview" ? "active_submenu" : ""}
            icon={<img src={Calendar_icon} className="w-75"/>}
          >
            <Link to="/Calendarview" onClick={menuIconClick}>  <span style={{color:'#003366'}}>Calendar</span></Link>
          </MenuItem>
        </Menu>

        {/* <Menu >
          <MenuItem className={props.url === "/OnlineExam" ? "active_submenu" : ""} icon={<img src={Online_Exam_icon} />
          }><Link to="/OnlineExam"  >
              Online Exam</Link></MenuItem>
        </Menu>
        <Menu >
          <MenuItem className={props.url === "/Assignments" ? "active_submenu" : ""} icon={<img src={Assignments_icon} />
          }><Link to="/Assignments"  >

              Assignments</Link></MenuItem>
        </Menu> */}

        <Menu>
          <MenuItem
            className={props.url === "/SessionList" ? "active_submenu" : ""}
            icon={<img src={Batch_Schedule_Approver} className="w-75" />}
          >
            <Link to="/SessionList" onClick={menuIconClick}></Link>
            <span style={{color:'#003366'}}>Session List </span>
          </MenuItem>
        </Menu>
        <Menu>
          <MenuItem
            className={props.url === "/Materials" ? "active_submenu" : ""}
            icon={<img src={book} />}
          >
            <Link to="/Materials" onClick={menuIconClick}><span style={{color:'#003366'}}>Study Materials </span> </Link>
          </MenuItem>
        </Menu>
        {/* <Menu >
          <MenuItem className={props.url === "/ViewReportCard" ? "active_submenu" : ""} icon={<img src={View_Report_Card_icon} />
          }><Link to="/ViewReportCard"  >
              View Report Card </Link></MenuItem>
        </Menu> */}

        {/* <Menu >
          <SubMenu
            className={test_maker ? "active_menu" : "white"}
            icon={<img src={Batch_Schedule_Approver} />
            }
            title='Batch Scheduling'
          >
            <MenuItem><Link to="/BatchSchedulingViewApprover" >Schedule Status View</Link></MenuItem>
          </SubMenu>
        </Menu> */}
        {/* Session */}


        

        {/* Assessment*/}

        {/* {
          AuthorizedFunction([
            userUIRole.uint_maker_creation || userUIRole.uint_view_maker 
            ||
            userUIRole.Lesson_view_maker || userUIRole.Lesson_maker_creation
            ||
            userUIRole.Content_view_maker || userUIRole.Content_maker_creation
          ]) && */}
        <Menu>
          <SubMenu
            className={
              props.url === "/ViewGradeSubmitList" ? "active_submenu" : ""
            }
            icon={<img src={assessmenticon}  />}
            title="Assessment"
          >
            {
              // AuthorizedFunction([userUIRole.uint_view_maker ]) &&
              <MenuItem
                className={
                  props.url === "/AssessmentBeneficiaryView" ||window.location.pathname.includes("/AssessmentBeneficiaryView") 
                    ? "active_submenu"
                    : "white"
                }
              >
                <Link to="/AssessmentBeneficiaryView" onClick={menuIconClick}> Assessment Beneficiary View</Link>
                
              </MenuItem>
            }
            {
               <MenuItem
               className={
                 props.url === "/MockView"||window.location.pathname.includes("/MockView")
                   ? "active_submenu"
                   : "white"
               }
             >
               <Link to="/MockView" onClick={menuIconClick}> Final Exam View</Link>
               
               
             </MenuItem>
            
            }
           
          </SubMenu>
        </Menu>
        <Menu>
          <SubMenu
            className={
              props.url === "/Reports" ? "active_submenu" : ""
            }
            icon={<img src={assessmenticon}  />}
            title="Reports"
          >
            {
              // AuthorizedFunction([userUIRole.uint_view_maker ]) &&
              <MenuItem
                className={
                  props.url === "/StudentPerformanceReport" ||window.location.pathname.includes("/StudentPerformanceReport") 
                    ? "active_submenu"
                    : "white"
                }
              >
                <Link to="/StudentPerformanceReport" onClick={menuIconClick}> Student performance report</Link>
                
              </MenuItem>
            }
            {
               <MenuItem
               className={
                 props.url === "/StudentAttendenceReport"||window.location.pathname.includes("/StudentAttendenceReport")
                   ? "active_submenu"
                   : "white"
               }
             >
               <Link to="/StudentAttendenceReport" onClick={menuIconClick}> Student attendance report</Link>
               
             </MenuItem>
            
            }
           
          </SubMenu>
        </Menu>
        {/* <Menu >
          <MenuItem
            className={props.url === "/Reports" ? "active_submenu" : "inactive_submenu"}
            icon={<GetSvgIcon iconName={"reports"} color={props.url === "/Reports" ? "white" : "#0d2f55"}></GetSvgIcon>}
            >
            <Link to="/Reports">Reports </Link>
          </MenuItem>
          <MenuItem
                  className={
                    props.url === "/StudentPerformanceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentPerformanceReport">
                    {" "}
                    Student Performance Report
                  </Link>
                </MenuItem>
                <MenuItem
                  className={
                    props.url === "/StudentAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentAttendenceReport">
                    {" "}
                    Student Attendence Report
                  </Link>
                </MenuItem>
        </Menu> */}
        {
          <Menu >
          <MenuItem
            className={props.url === "/Certificate" ? "active_submenu" : "inactive_submenu"}
            // icon={<GetSvgIcon iconName={"certificate"} color={props.url === "/Certificate" ? "#0d2f55" : "#0d2f55"}></GetSvgIcon>}
            // >
            icon={<img src={certificateIcon} style ={{width: '100%'}}  />} >
            <Link to="/Certificate" ><span style={{color:'#003366'}}>Certificate</span></Link>
          </MenuItem>
        </Menu>
        }
          
        {/* } */}
        {/* Report */}
{/* 
        {AuthorizedFunction([
          userUIRole.Assessment_Approvar_view ||
            userUIRole.AssessmentSchedule_Approvar_view,
        ]) && (
          <Menu>
            <SubMenu
              className={report ? "active_menu" : "white"}
              icon={<img src={assessment} className="w-50" git />}
              title="Reports"
            >
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_view_maker,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/StudentAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentAttendenceReport">
                    {" "}
                    Student Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/StudentPerformanceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/StudentPerformanceReport">
                    {" "}
                    Student Performance Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/TeacherAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/TeacherAttendenceReport">
                    {" "}
                    Teacher Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/BatchAttendenceReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/BatchAttendenceReport">
                    {" "}
                    Batch Attendence Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/InterventionMonthlyReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/InterventionMonthlyReport">
                    {" "}
                    Intervention Monthly Report
                  </Link>
                </MenuItem>
              )}
              {AuthorizedFunction([
                userUIRole.AssessmentSchedule_Approvar_view,
              ]) && (
                <MenuItem
                  className={
                    props.url === "/BatchSessionsReport"
                      ? "active_submenu"
                      : "white"
                  }
                >
                  <Link to="/BatchSessionsReport"> Batch Sessions Report</Link>
                </MenuItem>
              )}
            </SubMenu>
          </Menu>
        )} */}

      </SidebarContent>
    </ProSidebar>
    </div>
  );
};

export default Sidebar;
