import React, {  useState, useEffect } from "react";
import './course.scss';
import CourseStatus from '../Dashboard/CourseStatus';
import { useNavigate } from 'react-router-dom';
import { capitalize } from "../../../util/Utilities";
const ModuleItem = (props) => {
    const navigate = useNavigate()
    const {module, moduleName, currentStatus, manageRedirection, moduleRedirectionButton = true, coursedata,index, noCourse} = props;


    

    console.log("available",coursedata)
    return(
        <React.Fragment>
            {
                
                <div className='module_box'>
                    <div className='reminder_left'>
                        <div className='module_for'>
                            <h5 className='reminder_text'><span className="module_name">{coursedata?.name}</span> : <span className="modules_description">{capitalize(coursedata?.description)}</span></h5>
                            <div className='course_progress'>
                               <div className='progress_left'>
                                    {currentStatus > 0 ? <CourseStatus currentStatus={coursedata?.completedPercentage}/> : ''}
                               </div>
                               
                               {currentStatus > 0 ? <div className='progress_right'>
                                    <span className='progress_value'>{coursedata?.completedPercentage}% {coursedata?.completedPercentage > 50 && <span class="complete_module_text" style={{color: "green", padding: 10}}>  {'Quiz is enabled'}</span>}</span>
                               </div> : ''}
                            </div>
                        </div>
                    </div>
                    {moduleRedirectionButton && <div className='reminder_right'>
                        <div className='navigate_to'>
                            <span className='navigate_icon_ac' onClick={()=>{manageRedirection(coursedata,index)}}><i class="fa-solid fa-angle-right"></i></span>
                        </div>
                    </div>}
                </div>
            
}

        </React.Fragment>
    )
}

export default ModuleItem;