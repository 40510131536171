import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import Loader from "../../DefaultComponent/Loader";
import ModuleItem from "./ModuleItem";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentAccordian from "./ContentAccordian";
import Accordion from "react-bootstrap/Accordion";
import { dammyData } from "../../../util/videoData";
import CustomBreadCrumbs from '../../DefaultComponent/appComponent/CustomBreadCrumbs'

import axios from "axios";
import ManageQuiz from "./lessons/ManageQuiz";
import Quiz from "./../OnlineExam/Quiz";
import { useKeycloak } from "@react-keycloak/web";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import { Assessmenttime } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { AssessmentDataByAssessmentId } from "../../../services/ApiCaller/assessment/AssessmentManagement/AssessmentDataManagementController";
import { ASSESSMENT } from "../../../util/Constants";
import contentcomingsoon from "../../../template/img/contentcomingsoon.png";
import ContentViewModal from "./lessons/ContentViewModal";
import { fetchModuleData, filterCourseByStatus, filterLessonByStatus } from "../../../services/ApiCaller/content/lesson";
import Comingsoon from "../../DefaultComponent/appComponent/ComingSoon";

const ModuleContentView = () => {

  const [show, setShow] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const { courseId, lessonId } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [menuCollapse, setMenuCollapse] = useState(
    sidebarCollapsed ? false : true
  );
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("");
  const [listOfLessons, setLessons] = useState([]);
  const [showModal, setModalShow] = useState(false);
  const { keycloak } = useKeycloak();
  const [userData, setUserData] = useState(null);
  const [courseDetails, setcourseDetails] = useState(null);
  const [assessments, setAsssessments] = useState([]);
  const [moduleDetails, setModuleDetails] = useState(null)

  const manageRedirection = () => {
    // navigate('');
  };

  //#################################################### START OF USE EFFECTS #########################################//

  useEffect(() => {
    fetchUrl();
    console.log("query params", courseId, lessonId);
    fetchModerateUserList();
    fetchAssessmentDetails();
    contentFilter()
    fetchModuleDetails()
    fetchcourseName()
  }, []);

  

  //#################################################### END OF USE EFFECTS #########################################//

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
    console.log(urlElements, "urlElements");
  };

  const fetchAssessmentDetails = () => {
    setLoading(true);
    const resp = AssessmentDataByAssessmentId(courseId, lessonId, ASSESSMENT);
    resp.then(
      (success) => {
        setLoading(false);
        if (success.length > 0) {
          console.log("ASSESSMENT DETAILS SUCCESS", success)
          setAsssessments(success)
        } else {
        }
      },
      (error) => { 
        console.log("ASSESSMENT DETAILS ERROR", error)
        setLoading(false)}
    );
  };

  const fetchModuleDetails = () => {
    setLoading(true);
    const resp = fetchModuleData(lessonId);
    resp.then(
      (success) => {
        setLoading(false);
        // if (success.length > 0) {
          console.log("ASSESSMENT DETAILS SUCCESS", success)
          setModuleDetails(success)
        // } else {
        // }
      },
      (error) => { 
        console.log("ASSESSMENT DETAILS ERROR", error)
        setLoading(false)}
    );
  };

  const fetchModerateUserList = () => {
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
      setLoading(true)
    const response = getUserByUserId(filterdata);
    response.then(
      (success) => {
        setLoading(false)
        setUserData(success);
        // fetchAssessmentTime(courseId);
      },
      (error) => {
        setLoading(false)
      }
    );
  };


  const fetchcourseName = () => {
    //ONLY APPROVED
    setLoading(true)
    const response = filterCourseByStatus(courseId, ["Approved"]);
    response.then(
      (success) => {
        setLoading(false);
        setcourseDetails(success);
        console.log("COURSE DETAILS", success)
        //  setlessonId(success.unit.id);
        // console.log("idddd", success.unit.id)
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };

  const contentFilter = (data) => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      // http://164.52.204.237:10003/api/v1/lms-content/content/student/getByLesson/1
      axios
        .post(
          `content/api/v1/lms-content/content/student/getByLesson/${lessonId}`
        )
        .then(function (response) {
          setLoading(false)
          setLessons(response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          setLoading(false)
          console.log("Content Filter Error");
          reject(error);
        });
    });
  };

  const onQuizStartClick = () => {
    setModalShow(true);
  };

  const onContentItemClick = (lessionItem) => {
    console.log(lessionItem)
    setSelectedContent(lessionItem);
    setShow(true)
  }

  const onModalDissmiss = () => {
    setShow(false)
  }

  return (
    <React.Fragment>
      <Header />
  <div className='app'>
    <Sidebar />
    <div className='mainpage px-3 pt-2'style={{width:'100%'}} > 
                    <div class="page_main">
                        <div class="page_inner reports_main">
                        {/* <p class="h5 mt-3 mb-4 report_table_title">Student Attendence Report</p> */}
                        <div class="page_inner">
        <CustomBreadCrumbs/>

      {showModal && (
        <Quiz
          ExamFlag={true}
          show={showModal}
          backdrop={"static"}
          onHide={() => {
            setModalShow(false);
          }}
          courseid={courseId}
          lessonid={lessonId}
          userData={userData}
          // quizTotalDuration={quizTotalDuration}
          FROM={"QUIZ"}
        />
      )}

      <div className="module_content_view_main">
        <div className="model_content_view_header">
          <div className="row">
            <div className="col-lg-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              {moduleDetails && (
                <ModuleItem
                  module="5"
                  moduleName="Lorem ipsum dolor sit amet"
                  currentStatus="0"
                  manageRedirection={manageRedirection}
                  moduleRedirectionButton={false}
                  coursedata={moduleDetails}
                  index={0}
                />
              )}
            </div>
            {/* <div className="col-lg-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="next_btn_area">
                {currentIndex + 1 < modules.length && (
                  <button className="next_btn" onClick={handleNextClick}>
                    Next
                  </button>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="module_content_sec">
          <div className="row">
            <div className="col-12">
              <div className="module_content_main">
                <div className="module_sec_title">
                  <h5>Module Lessons</h5>
                </div>
                <div className="accordian_content">
                  <Accordion
                    onSelect={(e) => {
                      console.log("EVENT", e);
                      setActiveKey(e);
                      if (e !== null) {
                        // leaderDownloadfile(e)
                      }
                    }}
                  >
                    {/* {!loading && listOfLessons.length === 0 ? (
                      <Comingsoon></Comingsoon>
                    ) : (
                      listOfLessons.map((item, index) => {
                        return item.type != "scorm" ? (
                          <ContentAccordian
                            lessionItem={item}
                            index={index}
                            activeKey={activeKey}
                            onContentItemClick = {onContentItemClick}
                            
                          />
                        ) : (
                          <></>
                        );
                      })
                    )} */}
                    {(!loading && listOfLessons.length === 0) &&
                      <Comingsoon src={contentcomingsoon}></Comingsoon>
                    }
                    { listOfLessons.length > 0 &&
                      listOfLessons.map((item, index) => {
                        return item.type != "scorm1" ? (
                          <ContentAccordian
                            lessionItem={item}
                            index={index}
                            activeKey={activeKey}
                            onContentItemClick = {onContentItemClick}
                          />
                        ) : (
                          <></>
                        );
                      })
                    }
                  </Accordion>

                  {/* {(moduleDetails?.completedPercentage === 100 &&
                    assessments.length > 0) && (
                      <div className="quizStartBtn">
                        <ManageQuiz
                          quizTitle="Start Quiz"
                          onQuizStartClick={onQuizStartClick}
                        />
                      </div>
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
                        </div>
                    </div> */}
      {/* </>
                    :
                    <Loader />
                  }
                </main> */}
      {/* </div> */}

         {show &&  <ContentViewModal show={show} onModalDissmiss = {onModalDissmiss} selectedContent={selectedContent}/>}       
         </div>
         </div>
         </div>
         </div>
         </div> 
    </React.Fragment>
  );
};

export default ModuleContentView;
