import axios from '../../../axiosconfig';
// downloadTemplate
export function assessmentdownloadTemplate(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`files/files/download/${data}`,{ responseType: 'blob' })
       .then(function (response) {
         resolve(response.data);
         let d = new Date();
       
        //  console.log("header",response.headers)
          //  const suggestedFileName = response.headers[fileNameHeader];
        //  const effectiveFileName = (suggestedFileName === undefined
        //              ? "assessmentUpload.xls"
        //              : suggestedFileName);
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `assessmentUpload-${d.toDateString()}.pdf`); //or any other extension
     document.body.appendChild(link);
     link.click();
       })
       .catch(function (error) {
         reject(error);
         
         // console.log('Error on Authentication');
       });
     });
  }
  export function assessmentuploadTemplate(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.post('lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/offline/upload',data,{
      headers: {
          //'Access-Control-Allow-Origin':'*',
          "Accept": "application/json",
          "Content-Type": "application/json"
      }
    })
       .then(function (response) {
         resolve(response.data);
    //      const url = window.URL.createObjectURL(new Blob([response.data]));
    //  const link = document.createElement('a');
    //  link.href = url;
    //  link.setAttribute('download', 'file.xlsx'); //or any other extension
    //  document.body.appendChild(link);
    //  link.click();
       })
       .catch(function (error) {
         reject(error);
         
         // console.log('Error on Authentication');
       });
     });
  }