import jwt_decode from "jwt-decode";


const TOKEN_KEY = 'jwt-secret';


//set Token in localstorage
export const login = (token) => {
    const tkn = localStorage.getItem("jwt-secret")
    if (!tkn || tkn === ""){
    const currTime = new Date()
    // localStorage.setItem("last_login_time", `${new Date(currTime).toLocaleDateString()} ${new Date(currTime).toLocaleTimeString()}`)}
    localStorage.setItem("last_login_time", `${new Date(currTime).toLocaleTimeString()}`)}
    localStorage.setItem(TOKEN_KEY, token);
}



//check isValid
export const validJwt=(token)=>{
    try{
        jwt_decode(token);
    }catch(e){
        // console.log('erorr invalid token'+e)
        return false;
    }
    return true;
}


export const token = () => {
    // if(keycloak.authenticated)
        return localStorage.getItem(TOKEN_KEY);
}

export const delay_time =()=>{
    return 3000;
}

export const resetFields=()=>
{
    setTimeout(()=>{window.location.reload()},3000)
}
// export const validRole =()=>
// {
//     const role=localStorage.getItem("values");
//     // console.log("values from localstoage",typeof(role))

// }
//logout using keyClock


// export const login = (token) => {
//     localStorage.setItem(TOKEN_KEY, token);
// }



// export const isLogin = () => {
//     if (localStorage.getItem(TOKEN_KEY)) {
//         return true;
//     }

//     return false;
// }




// export const userDetails=()=>{
//     if(!validJwt(token()))
//         return '';
//     return jwt_decode(token());
// }



// export const validRole =(roles)=>{


//     if(roles.includes(Role.ANY))
//         return true;
    
//     if(!validJwt(token()))
//         return false;

//     const  user= userDetails();
    
//     // console.log(user)
    
//     return roles.includes(user.role);
// }

// export const dashBoardPath=(role)=>{
//     if(role === Role.ADMIN)
//         return "/admin/dashboard";
//     else if(role === Role.MANAGER){
//         return "/manager/dashboard";
//     } else if(role === Role.USER){
//         return "/user/dashboard";
//     }

// }
// export const documentPath =(role)=>
// {
//     if(role ===Role.ADMIN)
//     return "/admin/documents";
//     else if(role === Role.MANAGER)
//     return "/manager/documents";
//     else if(role === Role.USER)
//     return "/user/documents";
// }

// export const role=()=>{
//     const  user= userDetails();
//     return (user && user.role);
// }


