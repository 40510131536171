import { useEffect, useState } from 'react';
import Loader from '../../DefaultComponent/Loader';
import Header from "../../layout/Header";
import '../../layout/layout.scss';
import Sidebar from '../../layout/Sidebar';
export default function OnlineExam() {
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    useEffect(() => {
      fetchUrl();
    }, [])
    const fetchUrl = () => {
      let urlElements = window.location.href.split('/');
      let urlElelement = (urlElements[3])
      setCurrentUrl(`/${urlElelement}`)
    }
    return (
        <>
            <Header />
            <div className=' app' >
                {/* Sidebar */}
                < Sidebar url={currentUrl}/>
                {/* main comp */}
                <main >
                {!loading ?
                <>
                    {/* page title */}
                    <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                                Hi , 
                            </div>
                        </div>
                        {/* <div className='col d-flex justify-content-end'> <button
                    type="button"
                    className="btn lmsbtn btn-sm"
                    onClick={() => setInterventionsShow(true)}
                  >
                    Add Intervention
                  </button>
                  </div> */}
                    </div>
                    {/* main page  */}
                    <div className='mainpage' > </div>
                    </>
                    :
                    <Loader />
                  }
                </main>
            </div>
        </>
    )
}
