import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top', // Adjust the legend position as needed
      },
      title: {
        display: false,
        text: 'Stacked Bar Chart',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        position: 'bottom', // Set the x-axis position to bottom
      },
      y: {
        stacked: false,
        ticks: {
          // beginAtZero: true,
          stepSize: 1
        },
      },
    },
    maintainAspectRatio: false, // Add this line to disable aspect ratio
    //  height: 800, 
  };

// const labels = ['Jul','Aug', 'Sep', 'Oct', 'Nov','Dec'];

// // Generate random data using faker
// const generateRandomData = () => {
// //   return labels.map(() => Math.random({ min: -1000, max: 1000 }));
//   return 
// // return labels.map(() => Math.random() * 1000);
// };

// console.log("USER DETAILSSSSS", JSON.stringify(generateRandomData(), 0, 2))

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Time Spent',
//       data: generateRandomData(),
//       borderRadius: 20,
//       backgroundColor: '#0A233E',
//     }
//     // {
//     //   label: 'New Users',
//     //   data: generateRandomData(),
//     //   borderRadius: 10,
//     //   backgroundColor: '#D9D9D9',
//     // },
//     // {
//     //   label: 'Dataset 3',
//     //   data: generateRandomData(),
//     //   backgroundColor: 'rgb(53, 162, 235)',
//     // },
//   ],
// };

export function UserTrandGraph(props) {
  const {data} = props
  const [labels, setLabels] = useState([])
  let months = []
  let hour = []
  // let userCounts = []
  useEffect(() => {
    // setLabels(data.monthHour.map((item) => item.month))
  }, [])
        Object.values(data).map((item) => {
            console.log("fetchMasterPopList", item.month)
          // let singleData = {
          //   value: item.month,
          //   label: item.PRAO_ALT_COM_OFCR_NM,
          //   PopRegs: item.PRAO_ID_PK,

          // };
          months.push(item.month);
          hour.push(item.hour)
          // userCounts.push(item.userCount)

        });
  let datas 
  console.log(months,"mooo666666")
  const generateRandomData = () => {
      // return data.monthHour.map((item) => itemour)
datas= {
        labels: months,
        datasets: [
          // {
          //   label: 'Dataset 1',
          //   data: Utils.numbers(NUMBER_CFG),
          //   borderColor: Utils.CHART_COLORS.red,
          //   backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
          // },
          // {
          //   label: 'Dataset 2',
          //   data: Utils.numbers(NUMBER_CFG),
          //   borderColor: Utils.CHART_COLORS.blue,
          //   backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
          // }
        ]
      };
    };
console.log(data.month,"graph77")
  const graphData = {
    labels:months,
    datasets: [
      {
        label: 'Time Spent (In Hours)',
        data: hour,
        borderRadius: 10,
        backgroundColor: '#F13B23',
        barThickness: 20
      },
      // {
      //   label: 'New Users',
      //   data: userCounts,
      //   borderRadius: 10,
      //   backgroundColor: '#0D2F55',
      //   barThickness: 30
      // },
      // {
      //   label: 'New Users',
      //   data: generateRandomData(),
      //   borderRadius: 10,
      //   backgroundColor: '#D9D9D9',
      // },
      // {
      //   label: 'Dataset 3',
      //   data: generateRandomData(),
      //   backgroundColor: 'rgb(53, 162, 235)',
      // },
    ],
  };

    return (
        <div style={{ height: '400px', width: '100%' }}> {/* Set your desired height here */}
          <Bar options={options} data={graphData} />
        </div>
      );
}
