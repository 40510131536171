import axios from "../../../axiosconfig";

// Schedule assessment Validate
export function scheduleAssessmentValidate(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        "lms-assessment-service/api/v1/lms_assessment/schedule/validate",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Schedule assessment Send for approval
export function scheduleAssessmentSendForApproval(id, userid) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `lms-assessment-service/api/v1/lms_assessment/schedule/sendForApproval/${id}/${userid}`,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Schedule assessment Update
export function scheduleAssessmentUpdate(data) {
  return new Promise((resolve, reject) => {
    axios
      .put("lms-assessment-service/api/v1/lms_assessment/schedule/edit", data, {
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Schedule assessment Filter
export function scheduleAssessmentFilter(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "lms-assessment-service/api/v1/lms_assessment/schedule/filter",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Schedule assessment Creation
export function scheduleAssessmentCreation(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "lms-assessment-service/api/v1/lms_assessment/schedule/create",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Schedule assessment Creation
export function AssessmentSubmit(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/submit/assignment2",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
//fetch schedule assessment List by User Id
export function scheduleAssessmentByUserId(userId,inmonth) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `lms-assessment-service/api/v1/lms_assessment/schedule/fetchByUserId/${userId}/false`,inmonth,
        {
          headers: {
              //'Access-Control-Allow-Origin':'*',
              "Accept": "application/json",
              "Content-Type": "application/json"
          }
        })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function Assessmenttime(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `lms-assessment-service/api/v1/lms_assessment/data/mgmt/assessment/time/${userId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getUnitid(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/user/getUnitIdFromAssessmentId/${id}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//get schedule assessment by Id
export function scheduleAssessmentbyId(inId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`lms-assessment-service/api/v1/lms_assessment/schedule/get/${inId}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Schedule assessment delete by Id
export function scheduleAssessmentDeleteById(inId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `lms-assessment-service/api/v1/lms_assessment/schedule/delete/${inId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//store data on next button
export function storeTempDataOnNext(data) {
  return new Promise((resolve, reject) => {
    axios.post("lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/user/answer/post",data,
    {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      ).then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
//fetch temp data on previous button click
export function fetchTempDataOnPrevious(asse_Id,user_Id,ques_Id) {
  return new Promise((resolve, reject) => {
    axios.get(`lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/user/answer/get/${asse_Id}/${user_Id}/${ques_Id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
//fetch  data if network fails
export function fetchDataOnNetworkFail(asse_Id,user_Id) {
  return new Promise((resolve, reject) => {
    axios.get(`lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/user/answers/get/${asse_Id}/${user_Id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//for back end only...
//count Attempt On Continue exam
export function countAttemptOnContinue(user_Id,asse_Id,type) 
{
  return new Promise((resolve, reject) => {
    axios.get(`lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/push/user/attempts/${user_Id}/${asse_Id}/${type}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//start btn if count==2 then not able to start exam
export function attemptCountOnStart(user_Id,asse_Id) {
  return new Promise((resolve, reject) => {
    axios.get(`lms-assessment-service/api/v1/lms_assessment/attempt/mgmt/user/attempts/${user_Id}/${asse_Id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}




