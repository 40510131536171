import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import "../../template/all.scss";
import loginBanner from "../../template/img/loginEntry-Banner.svg";
import logo from "../../template/img/login-entry-logo.png";
import character from "../../template/img/character-img.svg";

import "./Loginpage.module.scss";

function LoginEntryPage(props) {
  return (
    <>
      <div className="login-entry-page">
        <div className="login-section-entry">
        <div className="login-entry-box-section">
            <img src={loginBanner} alt="" className="login-ban-img"/>
            <div className="login-entry-box">
                <span className="logo"><img src={logo} alt="" /></span>
                <div className="sign-in-txt">Sign In as</div>
                <div className="login-btns">
                    <button className="orange">Admin</button>
                    <br/>
                    <button className="red">Trainer</button>
                    <br/>
                    <button className="green">Beneficiary</button>
                </div>
            </div>
            <div className="character-img">
                <img src={character} alt="" />
            </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default LoginEntryPage;
