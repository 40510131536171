import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { isNumeric } from "../../../util/Utilities";
import { ModuleNames } from "ag-grid-community";

export default function CustomBreadCrumbs(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const paramm = useParams()
  console.log('sreeeeeee',paramm)
  let pathnames = location.pathname.split('/').filter((x) => x);
  const path = pathnames
  pathnames.splice(0, 4)
  // console.log("AFTER", pathnames)  

  const redirectToPath = (path) => {
    if(path.toLowerCase() === "modulecontentview"){
      navigate("ModuleContentView")
    }
    if(path.toLowerCase() === "modulecontentview"){
      navigate("ModuleContentView")
    }
  }
  return (
    <Box mt={2} mb={2}>
      <Breadcrumbs aria-label='breadcrumb' separator={"/"}>
        <div className='bread-crumbs-back' >
          <i class="fa-solid fa-circle-chevron-left" style={{color: "#0D2F55"}} onClick={() => {navigate("/Materials")}}></i>
          <p className="text-primary text-decoration-underline itemhover mb-0 ms-2" onClick={() => {navigate( "/Lesson/" + paramm.id  +  "/LessonContent/"+ paramm.courseId)}}>{"LessonContent"}</p>
        </div>
        {pathnames.map((value, index) => {
          return (

            <div className='col' onClick={() => {redirectToPath(value)}}>
              <p className="text-primary text-decoration-underline itemhover m-0">{value.replace("%20"," ")}</p>
            </div>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}
