import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top', // Adjust the legend position as needed
      },
      title: {
        display: false,
        text: 'Stacked Bar Chart',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        position: 'bottom', // Set the x-axis position to bottom
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    },
    maintainAspectRatio: false, // Add this line to disable aspect ratio
    //  height: 800, 
  };

const labels = ['Jul','Aug', 'Sep', 'Oct', 'Nov','Dec'];

// Generate random data using faker
const generateRandomData = () => {
//   return labels.map(() => Math.random({ min: -1000, max: 1000 }));
return labels.map(() => Math.random() * 1000);
};

export const data = {
  labels,
  datasets: [
    {
      label: 'Time Spent',
      data: generateRandomData(),
      borderRadius: 20,
      backgroundColor: '#0A233E',
    }
    // {
    //   label: 'New Users',
    //   data: generateRandomData(),
    //   borderRadius: 10,
    //   backgroundColor: '#D9D9D9',
    // },
    // {
    //   label: 'Dataset 3',
    //   data: generateRandomData(),
    //   backgroundColor: 'rgb(53, 162, 235)',
    // },
  ],
};

export function TimeSpent() {
    return (
        <div style={{ height: '400px', width: '100%' }}> {/* Set your desired height here */}
          <Bar options={options} data={data} />
        </div>
      );
}
