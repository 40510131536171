import axios from "../../../axiosconfig";

//  Assessment Data Validate
export function AssessmentDataValidate(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        "lms-assessment-service/api/v1/lms_assessment/data/mgmt/validate",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Assessment Data Send for approval
export function AssessmentDataSendForApproval(id, userid) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `lms-assessment-service/api/v1/lms_assessment/data/mgmt/sendForApproval/${id}/${userid}`,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Assessment Data Update
export function AssessmentDataUpdate(data) {
  return new Promise((resolve, reject) => {
    axios
      .put("lms-assessment-service/api/v1/lms_assessment/data/mgmt/edit", data, {
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Assessment Data Filter
export function AssessmentDataFilter(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "lms-assessment-service/api/v1/lms_assessment/data/mgmt/filter",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//  Assessment Data Creation
export function AssessmentDataCreation(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "lms-assessment-service/api/v1/lms_assessment/data/mgmt/create",
        data,
        {
          headers: {
            //'Access-Control-Allow-Origin':'*',
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//fetch assessment data List by User Id
export function AssessmentDataByUserId(inId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `lms-assessment-service/api/v1/lms_assessment/data/mgmt/getByUserId/${inId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
//fetch assessment data List by Assessment Id
export function AssessmentDataByAssessmentId(inId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `lms-assessment-service/api/v1/lms_assessment/data/mgmt/beneficiary/assessment/${inId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//get assessment Data by Id
export function AssessmentDatabyId(inId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`lms-assessment-service/api/v1/lms_assessment/data/mgmt/get/${inId}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Assessment data download template
export function AssessmentDataDownloadTemplate(inId) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "lms-assessment-service/api/v1/lms_assessment/data/mgmt/downloadTemplate"
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

//Assessment Data delete by Id
export function AssessmentDataDeleteById(inId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `lms-assessment-service/api/v1/lms_assessment/data/mgmt/delete/${inId}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
