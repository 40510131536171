import { useKeycloak } from '@react-keycloak/web';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../../layout/logo.png";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import "./assessment.scss";
import { Alert, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { attemptCountOnStart, scheduleAssessmentByUserId } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import DatePicker from "react-datepicker";

export default function AssessmentScheduleListApprover() {
  const currentDate = new Date()
  let todays_Date = moment(currentDate).format('DD-MM-YYYY')
  let todays_time = moment(currentDate).format('hh:mm')
  //view modal
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [userId, setUserId] = useState('')
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("")
  const [currentTimeDate, setCurrentTimeDate] = useState('')
  const [month, setMonth] = useState(new Date());

  const handleClose = () => {
    setShow(false)
    //props.autoSubmitData()
  }


  useEffect(() => {
    fetchUrl();
    fetchuserList()
    calculateDate()
  }, [month]);
  const calculateDate = () => {
    const date_is = new Date();
    setCurrentTimeDate(moment(date_is).format('DD-MM-YYYY hh:mm A'))

  }
  //VIEW USER
  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      setUserId(success.id)
      fetchListOfAssessments(success.id)
    }, error => {
    })
  }
  const fetchListOfAssessments = (userId) => {
    setLoading(true)
    const Date = moment(month).format("MM-yyyy");
    console.log(Date);
    const Data = JSON.stringify({
      "createdDate": Date,
      "statuses": []
    })
    console.log(Data);
    const resp = scheduleAssessmentByUserId(userId,Data)
    resp.then((success) => {
      setLoading(false)
      const filterOutData = success.filter(x => x.assessment.type === "Assessment")
      setSessionData(filterOutData)
      // console.log("filterOutData",filterOutData)
    }, error => {
      //add error msg
      setLoading(false)
    })
  }

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };
  //Select exam type online or offline
  const selectExamType = (ass_id, fileType, fileid, startTime, endTime) => {
    const resp = attemptCountOnStart(userId, ass_id)
    resp.then((success) => {
      console.log("count success", success.count)
      if (success.count === 2) {
        setShow(true)
        setMessage(`You have already Attemped Exam ${success.count} times`)
      }
      else {
        console.log("currentTime",moment().format('DD-MM-YYYY hh:mm A'))
        console.log("startTime",moment(startTime).format('DD-MM-YYYY hh:mm A'))
        console.log("endTime",moment(endTime).format('DD-MM-YYYY hh:mm A'))
        const currentTimes = moment();
const endTimeMoment = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

if (currentTimes.isSameOrAfter(endTimeMoment)) {
  // if (moment().format('DD-MM-YYYY hh:mm A') >= moment(endTime).format('DD-MM-YYYY hh:mm A')) {
    console.log("time over")
    // navigate(`/termsConditions/${ass_id}/${userId}`)
    setShow(true)
    setMessage(`Exam has Over on ${moment(endTime).format('DD-MM-YYYY')} at ${moment(endTime).format('hh:mm A')}`)
  }
  else if (moment().format('DD-MM-YYYY hh:mm A') < moment(startTime).format('DD-MM-YYYY hh:mm A')) {
    console.log("Exam Has Not Started Yet")
    setShow(true)
    setMessage(`Exam will start on ${moment(startTime).format('DD-MM-YYYY')} at ${moment(startTime).format('hh:mm A')}`)
  }
  else {
    if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      navigate(`/termsConditions/${ass_id}/${userId}`)
      // navigate(`/onlineMcqExam/${ass_id}`)
    }
    else if (fileType === "application/pdf") {
      navigate(`/offlineExam/${ass_id + "-KEF" + fileid}`)
    }
  }
}


    }, error => {

    })

  }



  return (
    <>
      {!loading ? (
        <>
          <Header />
          <div className=" app">
            {/* Sidebar */}
            <Sidebar url={currentUrl} />
            {/* main comp */}
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Assessment Beneficiary View</div>
                </div>
              </div>
              <div className='mainpage'>
                <div className="App">
                  <div className="col d-flex justify-content-end">
                  </div>
                  <div className="card">
                    <div className="card-body cardpositions main-crd">
                      <div className="row justify-content-center "></div>
              <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
          <label htmlFor="idRole" className="labelheading">
            Select Month <span className="errormark"> *</span>
          </label>
          <DatePicker
            className="form-control pickdate"
            selected={month}
            onChange={(date) => setMonth(date)}
            dateFormat="MM-yyyy"
            showMonthYearPicker
          />
        </div>
        </div>
                    </div>
                  </div>
                  </div>
              {/* main page  */}


              <div className="container-fluid" >
                {!loading ? <><div className='row px-3'>

      
                  {
                    sessionData && sessionData.map((item, index) => {


                      // setGetbatchdata(item.batch)
                      // {item.batch != null ? item.batch: "The meeting link is not available"}
                      return (
                        <div className='col-12 col-sm-12 col-ml-6 col-lg-4 col-xl-4 col-xxl-4 mt-3 mb-2'>
                        {/* Hover Demo*/}
                          <div className="column bgimg">
                            {/* Post*/}
                            <div className="post-module hover">
                              {/* Thumbnail*/}
                              <div className="thumbnail">
                              <div className="overlay-ribbon">
                                <div className="ribbon-content">
                                  <h3>{index+1}</h3>
                                </div>
                              </div>
                              {/* <div className="index">{index+1}</div> */}
                                <div className="date1">
                                  {/* <div className="month textdanger">day</div> */}
                                  {/* <div className="day">{item.assessment.mode}</div> */}

                                  {/* <div className="month textdanger">day</div> */}
                                </div>
                                <img src={icon} />
                                {/* <h1 className="title title-head ">{item.assessment.name}</h1> */}

                                {/* <div className="date1">{moment(item.startDateTime).format('DD MMM')} {moment(item.startDateTime).format('hh:mm A')} - {moment(item.endDateTime).format('DD MMM')}  {moment(item.endDateTime).format('hh:mm A')}</div> */}
                              </div>
                              
                              {/* Post Content*/}
                              <div className="post-content">
                              <h1 className="title title_head">{item.assessment.name}</h1>
                              <div className="time-limit">{moment(item.startDateTime).format('DD MMM')} {moment(item.startDateTime).format('hh:mm A')} - {moment(item.endDateTime).format('DD MMM')}  {moment(item.endDateTime).format('hh:mm A')}</div>
                                {/* <div className="category">{index + 1}</div> */}
                                {/* <div className="category datemoment">{moment(item.startDateTime).format('DD')} {moment(item.startDateTime).format('MMM')} - {moment(item.endDateTime).format('DD-MMM')}</div> */}
                                {/* <h1 className="title">{item.assessment.name}</h1> */}
                             
                                <p className="sub_title">
                                  {/* {console.log("item",item)} */}
                                  {/* {console.log("first, item" ,startDateTime )} */}
                                  {/* start Date Time : {moment(item.startDateTime).format('DD-MM-YYYY hh:mm A')} */}
                                </p>
                                <p className="sub_title">
                                  {/* start End Time : {moment(item.endDateTime).format('DD-MM-YYYY hh:mm A')} */}
                                </p>
                                

                                {
                                  item.attempted === true ?
                                    <button
                                      type="button"
                                      className="btn lmsbtn  btn-sm"
                                      disabled
                                    //   disabled={!isValid}
                                    >

                                      Submitted
                                    </button> :
                                    <div className="submit-btn">
                                      <button
                                        type="button"
                                        className="btn lmsbtn  btn-sm"
                                        //   disabled={!isValid}
                                        onClick={() =>
                                          selectExamType(
                                            item.assessment.id,
                                            item.assessment.fileType,
                                            item.assessment.fileId,
                                            item.startDateTime,
                                            item.endDateTime
                                          )}
                                      >

                                        Take Exam
                                      </button>
                                    </div>

                                }
                                <p className = " status">{item.assessment.mode}<span className = {item.assessment.mode === "Online" ? "dot-online" : "dot-offline"}></span></p>





                              </div>
                            </div>
                          </div>

                        </div>
                      )
                    })
                  }
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                  >

                    <Modal.Body style={{ textAlign: "center" }}>
                    <Alert  variant="danger">
                    <h5>
                        {message}
                    </h5>
        </Alert>
                      
                    </Modal.Body>
                    <Modal.Footer style={{ textAlign: "center" }}>
                      <Button  className="btn lmsbtn  btn-sm m-0" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                  </Modal>


                </div></>
                  :
                  <Loader />
                }


              </div>

            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
