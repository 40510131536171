import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect, useContext  } from "react";
import { useNavigate } from "react-router-dom";

import React from "react";
// import xheader from "../../../template/img/education_foundation.png";
import Header from "../../layout/Header";
import { TimeSpent } from "./TimeSpent";
import iconbg from "../../layout/logo.png";

import "./newDashboard.scss";
import Reminder from "./Reminders";
import LeaderBoard from "./LeaderBoard";
import { Controller, useForm } from "react-hook-form";
import CustomDropDown from "../../DefaultComponent/CustomDropDown";
// import {TimeSpent} from './TimeSpent';
import { filtergetByUserIdUnit } from "../../../services/ApiCaller/content/unit";
import {getUnitByUserId} from '../../../services/ApiCaller/calenddar/calenderapi'

import orange from "./../../../template/img/orange.png";
import onkar from "./../../../template/img/onkar.png";
import goldBatch from "./../../../template/img/gold_batch.png";
import rank2 from "./../../../template/img/rank2.png";
import rank3 from "./../../../template/img/rank3.png";
import RecentCourses from "./RecentCourses";
import totalCertificates from "./../../../template/img/total_certificates.svg";
import totalCourses from "./../../../template/img/total_courses.svg";
import pendingCourses from "./../../../template/img/pending_exams.svg";
import dashboardBanner from "./../../../template/img/dashboard_banner_img.svg";


import finance from "./../../../template/img/finance.png";
import banking from "./../../../template/img/banking.png";
import finance2 from "./../../../template/img/plaining.png";
import green from "./../../../template/img/green.png";
import radhika from "./../../../template/img/radhika.png";
import red from "./../../../template/img/red.png";
import naina from "./../../../template/img/naina.png";
import bronezeBatch from "./../../../template/img/broneze_batch.png";
import Select, { components } from "react-select";

import Sidebar from "../../layout/Sidebar";
// import { Row } from "react-bootstrap";
import "../../../../src/App.scss";
import { getUserByUserId ,getLeaderboardByUserId} from "../../../services/ApiCaller/user/usercontroller";

// import StudentPerformanceReport from "./../Reports/StudentPerformanceReport";
// import SessionCreation from "./../staticscreen/SessionEdit";
import { UserTrandGraph } from "./UserTrandGraph";
import DashboardCourseInfo from "./DashboardCourseInfo";
// import { pptDownLoad } from "./../../../services/ApiCaller/admin/beneficiary/leader/leaderApi";
import {
  dashboardDetails,
  dashboardGraphDetails,
} from "../../../services/ApiCaller/master/masterapi";
import Loader from "../../DefaultComponent/Loader";
import Notification from "../schedulerview/Notification";
import { select } from "react-dom-factories";
import * as Actions from "../../../app/state/Actions";

export default function Dashboard() {
  const navigate = useNavigate();
  const [allUnit,setAllUnit]=useState([])

  const handleunit = (item) => {
    console.log("itemmmm",item.id)
    navigate(`/Lesson/${item.id}`)
  }
  const { keycloak } = useKeycloak();
  const getUsername = () => {
    return (
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.name
    );
  };
  const getUserEmail = () => {
    return (
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.email
    );
  };
  const { state, dispatch } = Actions.getStateDispatch(useContext);
  const manageRedirection = (Coursedata, currentIndex) => {
    navigate(`/Course/${Coursedata.name.replace(" ", "-")}/${Coursedata.id}`, {
      state: {
        // modules: lesson,
        currentCourse: Coursedata,
        moduleIndex: currentIndex,
      },
    });
  };

  
  const [selectedYear, setSelectedYear] = useState("2023");

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  console.log("Selected Year:", selectedYear);
//  function getOptions(){
//   return dashboardValues?.courseList?.map((item=>  <option value={item.name}>{item.name}</option>))

// }
  useEffect(() => {
    fetchUrl();
    fetchDashboard();
    fetchDashboardGraph();
  }, [selectedYear]);
  
  const [currentUrl, setCurrentUrl] = useState("");
  const [dashboardValues, setDashboardValues] = useState([]);
  const [dashboardGraph, setDashboardGraph] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const[ dashboardGraphdata,setDashboardGraphdata] =useState([])
  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
} = useForm();
// const CustomValueContainer = ({ children, ...props }) => {
//   return (
//       <ValueContainer {...props}>
//           <Placeholder {...props} isFocused={props.isFocused}>
//               {props.selectProps.placeholder}
//           </Placeholder>
//           {React.Children.map(children, (child) =>
//               child && child.type !== Placeholder ? child : null
//           )}
//       </ValueContainer>
//   );
// };
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };
  const fetchUnit = () => {
    setLoading(true)
    setAllUnit([])
    const Data = JSON.stringify({
      "interventionId": "",
      "interventionLevelId":""
    })
    console.log(Data)
    // const response = filterUnit(Data)
    const response=filtergetByUserIdUnit(userlistid)
    response.then((success) => {
      
      setLoading(false)
      setAllUnit(success.slice(0,3))
    }, error => {
      setLoading(false)
      // setErrorObj(error)
      // setShowpopup(true);
      // setPopup(true)
    })
  }

  const [selectedOpt,setSelectedOption]=useState(null)
  // useEffect(()=>{
  //   console.log(selectedOpt,"selectop")
  //   if(selectedOpt)
  //   {
  //     setLoading(true);
  //     // setAllUnit([]);
  //     let filterdata =
  //       keycloak.authenticated &&
  //       keycloak.tokenParsed &&
  //       keycloak.tokenParsed.sub;
  //     getUserByUserId(filterdata).then((res) => {
  //       const response = dashboardDetails(res.id);
  //       response.then(
  //         (success) => {
  //           setLoading(false);
  //           setDashboardValues(success);
  //           setCourses(
  //             success.courseList.map((item) => ({
  //                 label: item.name,
  //                 value: item.id,
  //             }))
  //         );
  //           console.log(success, "dashhhhhhhhhh");
  //           let leaderboardData =  getLeaderboardByUserId(selectedOpt).then(e=>setData(e))
            
  //         },
  //         (error) => {
  //           setLoading(false);
  //           // setErrorObj(error)
  //           // setShowpopup(true);
  //           // setPopup(true)
  //         }
  //       );
  //     });
  //   }
  // },[])
 const [data,setData]=useState([])
  console.log("kkkkk " + currentUrl);
  function getLeaderboardCard(){
    let ranks={
      "RANK_1":goldBatch,
      "RANK_2":rank2,
      "RANK_3":rank3
    }
    return  data?.map(item=>  <LeaderBoard
       bgImage={orange}
       userImage={radhika}
       batch={ranks[item.rank]}
       leaderName={item.name}
       rank={item.rank}
       assessmentName={item?.assessmentName}
       CourseStatus={item.percentage}
       certificatesEarn="3"
     />)
    } 
    
    const [courses, setCourses] = useState();
    const[uid,setuid]=useState(null)
    const [userlistid,setUserListid]=useState()
    const [unitFetchedById, setUnitFetchedById] = useState()
    const [items, setItems] = useState([]);
    const [loginTime, setLoginTime] =  useState()


    useEffect(() => {
      setLoginTime(
        localStorage.getItem("last_login_time") || null
      )
      }, []);
    

  const fetchDashboard = () => {
    setLoading(true);
    // setAllUnit([]);
    let filterdata =
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.sub;
    getUserByUserId(filterdata).then((res) => {
      setUserListid(res.id)
      console.log('sreeeeee',res.monthHour)
      setDashboardGraphdata(res.monthHour)

      const response = dashboardDetails(res.id);
      response.then(
        (success) => {
          setLoading(false);
          setDashboardValues(success);
          setCourses(
            success.courseList.map((item) => ({
                label: item.name,
                value: item.id,
            }))
        );
          console.log(success, "dashhhhhhhhhh");
          let leaderboardData =  getLeaderboardByUserId(success.courseList[0].id).then(e=>setData(e))
          
        },
        (error) => {
          setLoading(false);
          // setErrorObj(error)
          // setShowpopup(true);
          // setPopup(true)
        }
      );
    });
  };

 
   
  const fetchDashboardGraph = () => {
    setLoading(true);
    // setAllUnit([]);
    const response = dashboardGraphDetails(selectedYear);
    response.then(
      (success) => {
        setLoading(false);
        setDashboardGraph(success);
        console.log(success, "dashhgraph");
      },
      (error) => {
        setLoading(false);
        // setErrorObj(error)
        // setShowpopup(true);
        // setPopup(true)
      }
    );
  };
  
  useEffect(() => {
    userlistid && fetchUnit();
    if (userlistid) {
      const recentUnits = getUnitByUserId(userlistid)
      recentUnits.then((res) => {
      setUnitFetchedById(res)
      })
    }
  }, [userlistid]);
  return (
    <>
      {!loading ? (
        <>
          <Header />
          <div className="app">
            {/* Sidebar */}
            <Sidebar url={currentUrl} />
            {/* main comp */}
            <main>
              {/* page title */}

              {/* main page  */}
              <div className="mainpage">
                <section>
                  <div className="container-fluid">
                    <div className="row">
                      {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="dashboard_box">
                          <h6 className="box_title">Total Courses</h6>
                          <div className="icon_content_sec">
                            <div className="box_icon">
                            {dashboardValues.totalCourse}
                             
                              <i class="fa-solid fa-circle-play"></i>
                             
                            </div>
                            <div className="box_text">
                              <p className="box_number">
                                {dashboardValues.totalUser}
                              </p>
                        
                              <span className="box_inner_text">Courses</span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="dashboad-sub-header row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <span className="morning-txt">
                            Welcome, {getUsername()}
                          </span>
                        </div>
                        {/* <div className="col-12 col-md-3">
                          <div class="search-container">
                            <form>
                              <input
                                type="text"
                                placeholder="Search.."
                                name="search"
                              />
                              <button type="submit">
                                <i class="fa fa-search"></i>
                              </button>
                            </form>
                            <div className="notify-icon">
                              <button type="submit">
                                <i class="fa fa-bell fa-regular"></i>
                              </button>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-time-container logintimemob">
                          <span className="loggedintime">Log In Time:&nbsp; </span>
                          <span className="time">{loginTime}</span>
                          <span className="log_time">
                                
                      </span>
                        </div>
                        
                        {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <div className="login_time_sec">
                            <p className="login_time">
                              Log In Time:{" "}
                              <span className="log_time">
                                {state.appReducer.appLoginTime}
                              </span
                            </p>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-12">
                        <div className="dashboard-banner">
                          <div className="banner-text">
                            <h2>hello !</h2>
                            <p>Let’s learn something new today!</p>
                          </div>
                          <img src={dashboardBanner} alt="" />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="dashboard_box">
                          <div className="box_graphic orange">
                            <img src={totalCourses} alt="" />
                          </div>
                          <div className="icon_content_sec">
                            {/* <div className="box_icon">
                            <i class="fa-solid fa-circle-play"></i>
                            </div> */}
                            <h6 className="box_title">Total Units</h6>

                            <div className="box_text">
                              <p className="box_number">
                                {dashboardValues.totalCourse}
                              </p>
                              <span className="box_inner_text"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="dashboard_box">
                          <div className="box_graphic red">
                            <img src={totalCertificates} alt="" />
                          </div>
                          <div className="icon_content_sec">
                            {/* <div className="box_icon">
                            <i class="fa-solid fa-circle-play"></i>
                            </div> */}
                            <h6 className="box_title">Total Certifications</h6>

                            <div className="box_text">
                              <p className="box_number">
                                {dashboardValues.totalCertificate}
                              </p>
                              <span className="box_inner_text"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="dashboard_box">
                          <div className="box_graphic pink">
                            <img src={pendingCourses} alt="" />
                          </div>
                          <div className="icon_content_sec">
                            {/* <div className="box_icon">
                            <i class="fa-solid fa-circle-play"></i>
                            </div> */}
                            <h6 className="box_title">Pending Exams</h6>

                            <div className="box_text">
                              <p className="box_number">
                                {dashboardValues.pendingExam}
                              </p>
                              <span className="box_inner_text"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="dashboard_box">
                          <h6 className="box_title">Total Certifications</h6>
                          <div className="icon_content_sec">
                            <div className="box_icon">
                              <i class="fa-solid fa-circle-play"></i>
                            </div>
                            <div className="box_text">
                              <p className="box_number">
                                {dashboardValues.totalCertificate}
                              </p>
                              <span className="box_inner_text">Courses</span>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* 
                      <section className="graphSection">
                        <div className="graph_header">
                          <p className="graph_title">User Trends</p>
                          <div className="graph_search">
                            <select
                              className="form-select select_bg"
                              aria-label="Default select example"
                              onChange={handleYearChange}
                              value={selectedYear}
                            >
                              <option disabled>Select Year</option>
                              <option value="2021">2021</option>
                              <option value="2022">2022</option>
                              <option value="2023">2023</option>
                            </select>
                          </div>
                        </div>
                        <UserTrandGraph data={dashboardGraph}/> 
                      </section> */}
                      <div className="newCourse_main">
                        <div className="row">
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 course-section">
                            <div className="section_title">
                              <p>My Recent Units</p>
                            </div>

                            <div className="row">
                    {/*ADD CLASSES HERE d-flex align-items-stretch*/}
                    {
                      unitFetchedById && unitFetchedById.map((item,index)=>{
                        return(
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-sm-12 my-2" 
                          onClick={() => handleunit(item)} >
                            <div className="card Materialsbodys shadow-lg p-4">
                              <div className="overlay-ribbon1">
                                <div className="ribbon-content">
                                  <h3>{index+1}</h3>
                                </div>
                              </div>
                              {/* <div className="unlitno shadow-lg"><p>1</p></div> */}
                              <img src={iconbg} className="card-img-top img-fluid" alt="Card Image" />
                              <div className="card-body d-flex flex-column">
                                <h5 className="card-title belonghover">{item.name}</h5>
                                {/* <p className="card-text1 belonghover mb-4">10 Lesson</p> */}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                   
        
                  </div>
                            <div className="view_all_btn_area">
                              <span className="btn_border"></span>
                              <button
                                className="view_course_btn"
                                onClick={() => navigate("/Materials")}
                              >
                                View all
                              </button>
                            </div>
                          </div>
                          {/* Reminder section */}
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="reminder_main">
                              <div className="reminder_header">
                                <h5 className="reminder_title">
                                  <i class="fa-regular fa-bell me-2"></i>
                                  Reminders
                                </h5>
                              </div>
                              <div className="reminder_content" >
                                <Notification/>
                                {/* <Reminder
                                  icon=<i class="fa-solid fa-square-check"></i>
                                  notificationFor="notify_course"
                                  title="Financial planning"
                                  module="3"
                                  time="5"
                                />
                                <Reminder
                                  icon=<i class="fa-solid fa-square-pen"></i>
                                  notificationFor="notify_exam"
                                  title="Financial planning"
                                  module="10"
                                  time="10"
                                />
                                <Reminder
                                  icon=<i class="fa-solid fa-square-check"></i>
                                  notificationFor="notify_course"
                                  title="Banking"
                                  module="3"
                                  time="5"
                                />
                                <Reminder
                                  icon=<i class="fa-solid fa-square-check"></i>
                                  notificationFor="notify_course"
                                  title="Banking"
                                  module="3"
                                  time="5"
                                />
                                <Reminder
                                  icon=<i class="fa-solid fa-square-check"></i>
                                  notificationFor="notify_course"
                                  title="Branch Management"
                                  module="3"
                                  time="5"
                                />
                                <Reminder
                                  icon=<i class="fa-solid fa-square-check"></i>
                                  notificationFor="notify_course"
                                  title="Branch Management"
                                  module="3"
                                  time="5"
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="leaderBoard_outer">
                          <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-sm-12 my-2">
                              <div className="leader_left_inner">
                                <div className="leaderBoard_main">
                                  <div className="leaderboard_title">
                                    <h5>Leader Board</h5>
                                  </div>
                                  <div className="leaderboard_sub_heading">
                                    <div className="leaderBoard_left">
                                      <h5>Top performers</h5>
                                    </div>
                                    <div className="leaderBoard_right">
                                      <label
                                        for="idLeaderGroupName"
                                        className="form-label leaderBoard_select_label"
                                      >
                                        <i class="fa-solid fa-sliders me-2"></i>
                                        Select Unit{""}
                                      </label>
                                      <Controller
                                                                                control={control}
                                                                                render={({
                                                                                    field: { onChange, value, name, ref },
                                                                                }) => {
                                                                                    // const currentSelection = [].find(
                                                                                    //   (c) => c.value === value
                                                                                    // );
                                                                                    const handleInterventions = (
                                                                                        selectedOption
                                                                                    ) => {
                                                                                      console.log(selectedOption,"selectoption")
                                                                                        // onChange(selectedOption);
                                                                                        // setSelectedOption(selectedOption?.value)
                                                                                        if(selectedOption?.value)
                                                                                        {
                                                                                          let filterdata =
                                                                                          keycloak.authenticated &&
                                                                                          keycloak.tokenParsed &&
                                                                                          keycloak.tokenParsed.sub;
                                                                                        getUserByUserId(filterdata).then((res) => {
                                                                                          const response = dashboardDetails(res.id);
                                                                                          response.then(
                                                                                            (success) => {
                                                                                              setLoading(false);
                                                                                              setDashboardValues(success);
                                                                                              setCourses(
                                                                                                success.courseList.map((item) => ({
                                                                                                    label: item.name,
                                                                                                    value: item.id,
                                                                                                }))
                                                                                            );
                                                                                              console.log(success, "dashhhhhhhhhh");
                                                                                              let leaderboardData =  getLeaderboardByUserId(selectedOption?.value).then(e=>setData(e))
                                                                                              
                                                                                            },
                                                                                            (error) => {
                                                                                              setLoading(false);
                                                                                              // setErrorObj(error)
                                                                                              // setShowpopup(true);
                                                                                              // setPopup(true)
                                                                                            }
                                                                                          );
                                                                                        });
                                                                                      // }
                                                                                        }
                                                                                        // setValue("intervention", selectedOption)
                                                                                        let str;
                                                                                        // if (selectedOption != null) {
                                                                                        //     setCourseFilterId(
                                                                                        //         selectedOption.value
                                                                                        //     );
                                                                                        // } else {
                                                                                        //     str = null;
                                                                                        //     setCourseFilterId(str);
                                                                                        // }
                                                                                    };
                                                                                    return (
                                                                                        <Select
                                                                                            // components={{
                                                                                            //     ValueContainer:
                                                                                            //         CustomValueContainer,
                                                                                            // }}
                                                                                            // isClearable
                                                                                            styles={CustomDropDown}
                                                                                            className="Selectontrol"
                                                                                            menuColor="red"
                                                                                            inputRef={ref}
                                                                                            value={value}
                                                                                            reset
                                                                                            name={name}
                                                                                            options={courses}
                                                                                            placeholder={
                                                                                                <label
                                                                                                    htmlFor="idCourse"
                                                                                                    className="labelheading"
                                                                                                >
                                                                                                    {" "}
                                                                                                </label>
                                                                                            }
                                                                                            onChange={handleInterventions}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                                name="course"
                                                                            />
                                      
                                    </div>
                                  </div>
                                </div>
                                <div className="leaderBoard_content_card">
                                  <div className="row">
                                    {getLeaderboardCard()}
                                    {data?.length ==0 && <p>'data not found'</p>}
                                  
                                    {/* <LeaderBoard
                                      bgImage={orange}
                                      userImage={radhika}
                                      batch={goldBatch}
                                      leaderName="Jaya Sigh"
                                      rank="1"
                                      CourseStatus="3"
                                      examScore="99"
                                      certificatesEarn="3"
                                    />
                                    <LeaderBoard
                                      bgImage={green}
                                      userImage={onkar}
                                      batch={silverBatch}
                                      leaderName="Somnath"
                                      rank="2"
                                      CourseStatus="2"
                                      examScore="97"
                                      certificatesEarn="2"
                                    />
                                    <LeaderBoard
                                      bgImage={red}
                                      userImage={onkar}
                                      batch={bronezeBatch}
                                      leaderName="Sreekanth"
                                      rank="3"
                                      CourseStatus="1"
                                      examScore="93"
                                      certificatesEarn="1"
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xs-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 time-spent-garph-box">
                              <div className="time_spend_main">
                                <div className="timespend_title">
                                  <h5>Time Spent (Last 6 Months)</h5>
                                  <UserTrandGraph data={dashboardGraphdata}/> 
                                  
                                </div>
                                {/* <TimeSpent /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className='demo' style={{width: '100%'}}>
                                        <TimeSpent />
                                   </div> */}
                        </div>
                      </div>
                      {/* <section className="dashboard_user_info_table">
                        <div className="dashboard_table_header">
                          <p className="dashboard_table_title">
                            Course Completion Rate
                          </p>
                        </div>
                        <DashboardCourseInfo courseAdoptionDetails={dashboardValues.courseAdoptionList}/>
                      </section> */}
                    </div>
                  </div>
                </section>
              </div>
            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
