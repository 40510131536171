
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Header from '../../layout/Header';
import Sidebar from '../../layout/Sidebar';
import Loader from '../../DefaultComponent/Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import './reports.scss'
import { useNavigate } from 'react-router-dom';
import { getreports } from "../../../services/ApiCaller/content/lesson";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useKeycloak } from '@react-keycloak/web';
import bannerImg from './../../../template/img/Certificate.png';

import Banner from '../../DefaultComponent/appComponent/Banner';

const headerHeight = 55;

export default function Reports() {
    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
  
    useEffect(() => {
      fetchUrl();
      fetchList();
    }, [fromDate,toDate]);
    const [loading, setLoading] = useState(false)
    const [currentUrl, setCurrentUrl] = useState("")
    const [showModal,setModalShow] = useState(false) 
    const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
    const [menuCollapse, setMenuCollapse] = useState(sidebarCollapsed ? false : true);
    const gridRef = useRef();



    // useEffect(() => {
    //     fetchUrl();
    //   }, [])
      const fetchUrl = () => {
        let urlElements = window.location.href.split('/');
        let urlElelement = (urlElements[3])
        setCurrentUrl(`/${urlElelement}`)
      }

    const columnDefs = [
        // {headerName: "S No.", field: "s_no", minWidth: 100},
        // {headerName: "Course Name", field: "course_name", minWidth: 90},
        // {headerName: "Max Marks", field: "max_marks",  minWidth: 90},
        // {headerName: "Max Scored", field: "max_scored", minWidth: 90},
        // {headerName: "Start Date", field: "start_date", minWidth: 130},
        // {headerName: "End Date", field: "end_date", minWidth: 130},
        // {headerName: "Completion Status", field: "completion_status", minWidth: 150},
        // {headerName: "Course Category", field: "course_category", minWidth: 100},
        // {headerName: "Certificate", field: "certificate",  minWidth: 140,
        // cellRendererFramework:(params) => {return <ViewIcon paramsData={params} onCertificateViewClick={onCertificateViewClick}/>
        // }},
        { headerName: "Sr. No.", field: "s_no", minWidth: 100 },
        { headerName: "Student Name", field: "studentName" },
        { headerName: "Assessment Name", field: "assignmentName" },
        { headerName: "Curriculum Name", field: "interventionName" },
        { headerName: "Curriculum ID", field: "interventionID" },
        { headerName: "School Name", field: "schoolName" },
        { headerName: "School ID", field: "schoolID" },
        { headerName: "Session ID", field: "sessionID" },
        { headerName: "Date of Assessment", field: "dateOfAssignment" },
        { headerName: "Time of Assessment", field: "timeOfSession" },
        { headerName: "Mode of Assessment", field: "onlineORoffline" },
        { headerName: "Total Marks", field: "totalMarks" },
        { headerName: "Marks Scored", field: "marksScored" },
        {
          headerName: "Comments",
          field: "comments",
          cellRendererFramework: (params) => (
            <div>
              <p>{params.data.comments != null ?  params.data.comments : "N/A"}</p>
            </div>
          ),
        },
    ]

    const onCertificateViewClick = (paramsData) => {
        navigate("/Certificate")
    }

    const [rowData, setRowData] = useState([]);
 // Filter List
 const { keycloak } = useKeycloak();

 const getUsername = () => {
  // console.log("nnn", keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.resource_access)
  return keycloak.authenticated && keycloak.tokenParsed  && ( `${keycloak.tokenParsed.given_name}${keycloak?.tokenParsed?.family_name ? " "+keycloak?.tokenParsed?.family_name:''}` )

}
 const fetchList = () => {
  setRowData([]);
  const fDate = moment(fromDate).format("DD-MM-YYYY")
  const tDate = moment(toDate).format("DD-MM-YYYY")
  const response = getreports(getUsername(),fDate,tDate);
  response.then(
    (success) => {
      setLoading(false);
      setRowData(success);
    },
    (error) => {
      setLoading(false);
      // setErrorObj(error);
      // setShowpopup(true);
      // setPopup(true);
      setRowData([]);
    }
  );
};
      const defaultColDef = useMemo(() => {
        return {
          editable: false, wrapText: true, autoHeight: true,
          sortable: true,
          resizable: true,
          filter: true,
          animation: false,
          flex: 1,
          // minWidth: 180,
        };
      }, []);
    return <React.Fragment>
        <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}/>
                {/* main comp */}
                <main >
                {!loading ?
                <>
                    {/* page title */}
                    {/* <div className='row pageheader justify-content-between'>
                        <div className='col'>
                            <div className='pagetitle'>
                               Calendar
                            </div>
                        </div>
                    </div> */}
                    {/* main page  */}
                    <div className='mainpage' > 
                    <div class="page_main">
                        <div class="page_inner reports_main">
                        <p class="h5 mt-3 mb-4 report_table_title"> Performance Report</p>
                        <div class="page_inner">
                        <Banner img = {bannerImg} styleCss = {'cetf_banner'}/>
                            <div class="certificate_list_outer">
                                {/* <CertificateList cerImage = {demoCert}/> */}
                            </div>
                        </div>
                        <div className="row justify-content-center ">
                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            From Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="Select Date"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="dd-MM-yyyy"
                            // minDate={new Date()}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>

                        <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idName"
                            className="labelheading d-block"
                          >
                            To Date
                          </label>

                          <DatePicker
                            className="form-control "
                            placeholderText="To Date"
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="dd-MM-yyyy"
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                          />
                        </div>

                      </div>
                        <div style={{height: "655px"}} className="ag-theme-alpine">
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    headerHeight={headerHeight}
                                    pagination={true}
                                    defaultColDef={defaultColDef}
                                    paginationPageSize={10}
                                    suppressColumnMoveAnimation={false}
                                    >
                                </AgGridReact>
                                {/* <AgGridReact
                        rowData={rowData}
                        paginationPageSize={10}
                        ref={gridRef} // Ref for accessing Grid's API
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        // rowSelection={"multiple"}
                        rowGroupPanelShow={"always"}
                        pivotPanelShow={"always"}
                        enableRangeSelection={true}
                        pagination={true}
                      ></AgGridReact> */}
                            </div>
                        </div>
                    </div>
                    </div>
                    </>
                    :
                    <Loader />
                  }
                </main>
            </div>

        </React.Fragment>
  
}

const ViewIcon = (props) => {
    const {onCertificateViewClick, paramsData} = props
    return <div>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" onClick={() => onCertificateViewClick(paramsData)} style={{cursor: 'pointer'}}>
        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
            fill={"#0A233E"}/></svg>
    </div>
}