import { useEffect, useState } from "react";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import Dashbordcalendar from "../schedulerview/dashbordcalendar";
import year from "../../../template/img/Academic year.svg";
import standard from "../../../template/img/standard.svg";
import batchimg from '../../../template/img/Check Document copy.svg'
import division from "../../../template/img/Division.svg";
import Notification from "./Notification";
import { useKeycloak } from '@react-keycloak/web';
import { getUserByUserId } from '../../../services/ApiCaller/user/usercontroller';
import {getuserDetails} from '../../../services/ApiCaller/calenddar/calenderapi'
export default function Dashboard() {
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [details, setDetails] = useState("");
   //SHOW ERROR MSGS
   const [errorMsg, setErrorMsg] = useState("");
   const [showPopup, setShowpopup] = useState(false);
   const [popup, setPopup] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  useEffect(() => {
    fetchUrl();
    fetchuserList();
    fetchDetails();
  }, [userId]);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };

  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      console.log("success", success)
      setUserName(success.name)
      setUserId(success.id)
    
    }, error => {
      setErrorObj(error);
      setLoading(false);
      setShowpopup(true);
      setPopup(true);
    })
  }

  console.log("first",userId)

  const fetchDetails = () => {
    if(userId){
    const response = getuserDetails(userId);
    response.then(
      (success) => {
        console.log("response", success);
        setDetails(success)
      },
      (error) => {
        setErrorObj(error);
        setLoading(false);
        setShowpopup(true);
        setPopup(true);
      }
    );}
  };

  return (
    <>
      {/* <Header /> */}
      <div className=" ">
        {/* Sidebar */}
        {/* <Sidebar url={currentUrl} /> */}
        {/* main comp */}
        <main>
          {!loading ? (
            <>
              {/* page title */}
              {/* <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Hi {userName},</div>
                </div> */}
                {/* <div className='col d-flex justify-content-end'> <button
                    type="button"
                    className="btn lmsbtn btn-sm"
                    onClick={() => setInterventionsShow(true)}
                  >
                    Add Intervention
                  </button>
                  </div> */}
              {/* </div> */}
              {/* main page  */}
              <div className="mainpage">
                <div className=" mb-3">
                  <div className="row">
                    <div className="col-12 col-xl-8 col-lg-8 col-xxl-7 col-lg-8 col-sm-12">
                      <div className="">
                        <img src={year} className="leaderboard__academic" />
                        <span className="academic">Academic Year:</span>
                        <span className="heading"><b>{details.academicYear ? details.academicYear : "N/A"}</b></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-xl-3 col-lg-3 col-xxl-3 col-lg-3 col-sm-12">
                      <div className="class details">
                        <img src={standard} className="leaderboard__class" />
                        <span className="academic">Class:</span>
                        <span className="heading"><b>{details.standard}</b></span>
                      </div>
                    </div>

                    <div className="col-12 col-xl-3 col-lg-3 col-xxl-3 col-lg-3 col-sm-12">
                      <div className="division details">
                        <img src={division} className="leaderboard__division" />
                        <span className="academic">Division:</span>
                        <span className="heading"><b>{details.division}</b></span>
                      </div>
                    </div>
                     <div className="col-12 col-xl-3 col-lg-3 col-xxl-3 col-lg-3 col-sm-12">
                      <div className="division details">
                        <img src={division} className="leaderboard__division " />
                        <span className="academic">Batch:</span>
                        <span className="heading"><b>{details.batchName}</b></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-xl-8 col-lg-8 col-xxl-7 col-lg-8 col-sm-12">
                    <div className="card dashbordcard">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col">
                            {" "}
                            <p>Calendar </p>
                          </div>

                          {/* <div className="col d-flex justify-content-end"><i class="fas fa-expand"></i></div> */}
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <Dashbordcalendar />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-4 col-lg-4 col-xxl-5 col-lg-4 col-sm-12">
                    <div className="card dashbordcard">
                      <div className="card-header py-3">
                        <p className="text-center m-auto"><i class="fa-regular fa-bell me-2"/>Reminders</p>
                      </div>
                      <Notification />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </>
  );
}
