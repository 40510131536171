import React from 'react';

const LeaderBoard = (props) => {
    const{bgImage, userImage, batch, leaderName, rank, assessmentName, CourseStatus, certificatesEarn} = props;
    return(
        <React.Fragment>
            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-2'>
            <div className="card text-center">
                <img src={bgImage} className="card-img-top leader_card_bg" alt="..." />
                <div className='profile_pic_main'>
                    <img src={userImage} className='leder_profile' alt='leader image'></img>
                    <span className='leader_batch'>
                        <img src={batch} alt='batch image'></img>
                    </span>
                </div>
                <div className="card-body cstm_card_body_style">
                    <h5 className="leader_name">{leaderName}</h5>
                    <p className='leader_rank'>{rank}</p>
                    <p className='course_completed card_content'>Percentage : <span className='d_value'>{CourseStatus}</span></p>
                    <p className='exam_score card_content'>Exam Name : <span className='d_value'>{assessmentName}</span></p>
                    {/* <p className="certificate_earn card_content">Certificates Earned : <span className='d_value'>{certificatesEarn}</span></p> */} 
                </div>
                </div>                                   
            </div>
        </React.Fragment>
    )
}

export default LeaderBoard;