import { useKeycloak } from '@react-keycloak/web';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import icon from "../../../template/img/footer-logo.svg";
import icon from "../../layout/logo.png";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import "./assessment.scss";
import { Alert, Button, Modal } from 'react-bootstrap';

import moment from 'moment';
import { scheduleAssessmentByUserId } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { getUserByUserId } from "../../../services/ApiCaller/user/usercontroller";
import DatePicker from "react-datepicker";


export default function MockView() {
  const currentDate = new Date()
  let todays_Date = moment(currentDate).format('DD-MM-YYYY hh:mm A')
  //view modal
  const navigate = useNavigate();
  const [month, setMonth] = useState(new Date());

  const { keycloak } = useKeycloak();
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("")
  const [examMode, setExamMode] = useState('')
  const [onlineExamPatterns, setOnlineExamPatterns] = useState('')
  const [assessmentfileType, setAssessmentfileType] = useState([])
  const [userId, setUserId] = useState('')

  const [currentTimeDate, setCurrentTimeDate] = useState('')
  
  const calculateDate = () => {
    const date_is = new Date();
    setCurrentTimeDate(moment(date_is).format('DD-MM-YYYY hh:mm A'))

  }

  
  const handleClose = () => {
    setShow(false)
    //props.autoSubmitData()
  }

  useEffect(() => {
    fetchUrl();
    fetchuserList()
  }, [month]);
  //VIEW USER
  const fetchuserList = () => {
    let filterdata = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub
    const response = getUserByUserId(filterdata)
    response.then((success) => {
      setUserId(success.id)
      fetchListOfAssessments(success.id)
    }, error => {
    })
  }
  const fetchListOfAssessments = (userId) => {
    setLoading(true)
    const Date = moment(month).format("MM-yyyy");

    const resp = scheduleAssessmentByUserId(userId,{
      "createdDate": Date,
      "statuses": []
  })
    resp.then((success) => {
      setLoading(false)
      const filterOutData = success.filter(x => x.assessment.type==="Mock")
      setSessionData(filterOutData)
      console.log("filterOutData",filterOutData)
    },error=>{
      //add error msg
      setLoading(false)
    })
  }

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };
  //Select exam type online or offline
  // const selectExamType = (ass_id,fileType,fileid) => {
    
  //   if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
  //     // navigate(`/termsConditions/${ass_id}`)
  //     navigate(`/termsConditions/${ass_id}/${userId}?mock=true`)

  //     // navigate(`/onlineMcqExam/${ass_id}`)
  //   } 
  //    else if (fileType === "application/pdf") {
  //     navigate(`/offlineExam/${ass_id + "-KEF" + fileid}`)
  //   }
  // }
  const selectExamType = (ass_id, fileType, fileid, startTime, endTime) => {
    // const resp = attemptCountOnStart(userId, ass_id)
    // resp.then((success) => {
      // console.log("count success", success.count)
      if (false) {
        setShow(true)
        setMessage(`You have already Attemped Exam  times`)
      }
      else {
        console.log("currentTime",moment().format('DD-MM-YYYY hh:mm A'))
        console.log("startTime",moment(startTime).format('DD-MM-YYYY hh:mm A'))
        console.log("endTime",moment(endTime).format('DD-MM-YYYY hh:mm A'))
        const currentTimes = moment();
const endTimeMoment = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

if (currentTimes.isSameOrAfter(endTimeMoment)) {
        // if (moment().format('DD-MM-YYYY hh:mm A') >= moment(endTime).format('DD-MM-YYYY hh:mm A')) {
          console.log("time over")
          // navigate(`/termsConditions/${ass_id}/${userId}`)
          setShow(true)
          setMessage(`Exam has Over on ${moment(endTime).format('DD-MM-YYYY')} at ${moment(endTime).format('hh:mm A')}`)
        }
        else if (moment().format('DD-MM-YYYY hh:mm A') < moment(startTime).format('DD-MM-YYYY hh:mm A')) {
          console.log("Exam Has Not Started Yet")
          setShow(true)
          setMessage(`Exam will start on ${moment(startTime).format('DD-MM-YYYY')} at ${moment(startTime).format('hh:mm A')}`)
        }
        else {
          if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      navigate(`/termsConditions/${ass_id}/${userId}?mock=true`)
            // navigate(`/onlineMcqExam/${ass_id}`)
          }
          else if (fileType === "application/pdf") {
            navigate(`/offlineExam/${ass_id + "-KEF" + fileid}?mock=true`)
          }
        }
      }


    }
 


  return (
    <>
      {!loading ? (
        <>
          <Header />
          <div className=" app">
            {/* Sidebar */}
            <Sidebar url={currentUrl} />
            {/* main comp */}
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Final Exam View</div>
                </div>
              </div>
              <div className='mainpage'>
                <div className="App">
                  <div className="col d-flex justify-content-end">
                  </div>
                  <div className="card">
                    <div className="card-body cardpositions main-crd">
                      <div className="row justify-content-center "></div>
              <div className="form-group  formgroup col-md-4 col-xxl-4 col-lg-4 col-xl-4 col-md-6 col-sm-12 col-12">
          <label htmlFor="idRole" className="labelheading">
            Select Month <span className="errormark"> *</span>
          </label>
          <DatePicker
            className="form-control pickdate"
            selected={month}
            onChange={(date) => setMonth(date)}
            dateFormat="MM-yyyy"
            showMonthYearPicker
          />
        </div>
        </div>
                    </div>
                  </div>
                  </div>
              {/* main page  */}

              
                            <div className="container-fluid" >
                {!loading ? <><div className='row px-3'>
                  {
                   sessionData && sessionData.map((item, index) => {
                    
                      return (
                        <div className='col-12 col-sm-12 col-ml-6 col-lg-4 col-xl-4 col-xxl-4 mt-3 mb-2'>
                        {/* Hover Demo*/}
                          <div className="column bgimg">
                            {/* Post*/}
                            <div className="post-module hover">
                              {/* Thumbnail*/}
                              <div className="thumbnail">
                              <div className="overlay-ribbon">
                                <div className="ribbon-content">
                                  <h3>{index+1}</h3>
                                </div>
                              </div>
                              {/* <div className="index">{index+1}</div> */}
                                <div className="date1">
                                  {/* <div className="month textdanger">day</div> */}
                                  {/* <div className="day">{item.assessment.mode}</div> */}

                                  {/* <div className="month textdanger">day</div> */}
                                </div><img src={icon} />
                                {/* <h1 className="title title-head ">{item.assessment.name}</h1> */}
                                
                              </div>
                              
                              {/* Post Content*/}
                              <div className="post-content">
                                {/* <div className="category">{index + 1}</div> */}
                                {/* <div className="category datemoment">{moment(item.startDateTime).format('DD')} {moment(item.startDateTime).format('MMM')} - {moment(item.endDateTime).format('DD-MMM')}</div> */}
                                <h1 className="title title_head">{item.assessment.name}</h1>
                                <div className="time-limit">{moment(item.startDateTime).format('DD MMM')} {moment(item.startDateTime).format('hh:mm A')} - {moment(item.endDateTime).format('DD MMM')}  {moment(item.endDateTime).format('hh:mm A')}</div>
                             
                                <p className="sub_title">
                                  {/* {console.log("item",item)} */}
                                  {/* {console.log("first, item" ,startDateTime )} */}
                                  {/* start Date Time : {moment(item.startDateTime).format('DD-MM-YYYY hh:mm A')} */}
                                </p>
                                <p className="sub_title">
                                  {/* start End Time : {moment(item.endDateTime).format('DD-MM-YYYY hh:mm A')} */}
                                </p>

                                {
                                  item.attempted === true ?
                                    <button
                                      type="button"
                                      className="btn lmsbtn  btn-sm"
                                      disabled
                                    //   disabled={!isValid}
                                    >

                                      Submitted
                                    </button> :
                                    <div className="submit-btn">
                                      <button
                                        type="button"
                                        className="btn lmsbtn  btn-sm"
                                        //   disabled={!isValid}
                                        onClick={() =>
                                          selectExamType(
                                            item.assessment.id,
                                            item.assessment.fileType,
                                            item.assessment.fileId,
                                            item.startDateTime,
                                            item.endDateTime
                                          )}
                                      >

                                        Take Exam
                                      </button>
                                    </div>

                                }
                                <p className = " status">{item.assessment.mode}<span className = {item.assessment.mode === "Online" ? "dot-online" : "dot-offline"}></span></p>





                              </div>
                            </div>
                          </div>

                        </div>
//                         <div className='col-12 col-sm-12 col-ml-6 col-lg-4 col-xl-4 col-xxl-4 mt-3 mb-2'>
//                         {/* Hover Demo*/}
//                     <div className="column">
                      
//                       {/* Post*/}
//                       <div className="post-module hover">
//                         {/* Thumbnail*/}
//                         <div className="thumbnail">
//                           <div className="overlay-ribbon">
//                           <div className="ribbon-content">
//                             <h3>{index+1}</h3>
//                           </div>
//                         </div>
//                         {/* <div className="index">{index+1}</div> */}
//                           <div className="date">
                          
//                             {/* <div className="day">{moment(item.date).format('DD')}</div>
//                             <div className="month">{moment(item.date).format('MMM')}</div>
//                             <div className="month">{moment(item.startTime).format('hh:mm A')}</div> */}
//                           </div><img src={icon} />
//                           <div className="date">{moment(item.startDateTime ).format('hh:mm A')} to {moment(item.endDateTime ).format('hh:mm A')}</div>

//                         </div>
//                         {/* Post Content*/}
//                         <div className="post-content">
//                           {/* <div className="category">{index+1}</div> */}
//                           <div className="date">
//                             <div className="category">{moment(item.startDateTime ).format('DD')} {moment(item.startDateTime).format('MMM')}</div>
//                             </div>

//                           <h1 className="title">{item.assessment.name}</h1>
                          
//                           {/* <h2 className="sub_title">Batch Name : {item.batchName ? item.batchName : "N/A"}</h2> */}

//                           <p className="description">
//                             Teacher :  {item.teacherFirstName ? item.teacherFirstName  : "N/A"}   {item.teacherLastName ? item.teacherLastName  : "N/A"}<br></br>
                            
                          
//                           {/* Academic Year: {item.academicYear ? item.academicYear : "N/A"}   ,  */}
//                           Intervention Name : {item.interventionName ? item.interventionName :"N/A"}<br></br>
//                           <span className='text'>Batch</span> : {item.batchName ? item.batchName : "N/A"}
                          
//                           </p>
//                           { 
//                                  item.attempted === true ? 
//                                  <>
//                                  <button
//                                      type="button"
//                                      className="btn lmsbtn  btn-sm"
//                                      //   disabled={!isValid}
//  >
                                  
//                                    Submitted 
//                                    </button></> : <>   
//                                    <div className="submit-btn">
//                                    <button
//                                      type="button"
//                                      className="btn lmsbtn  btn-sm"
//                                      //   disabled={!isValid}
//                                      onClick={() => 
//                                        selectExamType(
//                                          item.assessment.id,
//                                          item.assessment.fileType,
//                                          item.assessment.fileId
//                                          )}
//                                    >
                                  
//                                      Start
//                                    </button>
//                                  </div></>}
//                           {/* <div className="post-meta"><span className="timestamp"><i className="fas fa-clock" /> {moment(item.startTime).format('HH:MM A')}</span><span className="comments"><i className="fas fa-calendar" /> {moment(item.startTime).format('DD-MMMM-YYYY')}</span></div> */}
//                         </div>
//                       </div>
//                     </div>
//                     </div>
//                         <div className='col-12 col-sm-12 col-ml-6 col-lg-4  col-xxl-3 mt-3 mb-2'>
//                           {/* Hover Demo*/}
//                           <div className="column bgimg">
//                             {/* Post*/}
//                             <div className="post-module hover">
//                               {/* Thumbnail*/}
//                               <div className="thumbnail">
//                                 <div className="date">
//                                 <div className="month textdanger">day</div>
//                                 <div className="day">{item.assessment.mode}</div>

//                                   <div className="month textdanger">day</div>
//                                 </div><img src={icon} />
//                               </div>
//                               {/* Post Content*/}
//                               <div className="post-content">
//                                 <div className="category">{index+1}</div>
//                                 <h1 className="title">{item.assessment.name}</h1>
//                                 <p className="sub_title">
//                                   {console.log("item",item)}
//                                   {/* {console.log("first, item" ,startDateTime )} */}
//                                 start Date Time : {moment(item.startDateTime ).format('DD-MM-YYYY hh:mm A')}
//                                 </p>
//                                 <p className="sub_title">
//                                 start End Time : {moment(item.endDateTime).format('DD-MM-YYYY hh:mm A')}
//                                 </p>
//                                 {/* <p>{todays_Date} {moment(item.startDateTime ).format('DD-MM-YYYY hh:mm A')}</p> */}
//                                 {/* {
//                                     moment(todays_Date).format('DD-MM-YYYY hh:mm A')< moment(item.startDateTime ).format('DD-MM-YYYY hh:mm A') ?

//                                    <p>exam is not started yet</p>:
//                                    <p>exam over</p>
//                                 } */}
                              
//                                 { 
//                                 item.attempted === true ? 
//                                 <>
//                                 <button
//                                     type="button"
//                                     className="btn lmsbtn  btn-sm"
//                                     //   disabled={!isValid}
// >
                                  
//                                   Submitted 
//                                   </button></> : <>   
//                                   <div className="submit-btn">
//                                   <button
//                                     type="button"
//                                     className="btn lmsbtn  btn-sm"
//                                     //   disabled={!isValid}
//                                     onClick={() => 
//                                       selectExamType(
//                                         item.assessment.id,
//                                         item.assessment.fileType,
//                                         item.assessment.fileId
//                                         )}
//                                   >
                                  
//                                     Start
//                                   </button>
//                                 </div></>}
                               
                             
//                               </div>
//                             </div>
//                           </div>

//                         </div>
                      )
                    })
                  }

                </div></>
                  :
                  <Loader />
                }
<Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                  >

                    <Modal.Body style={{ textAlign: "center" }}>
                    <Alert  variant="danger">
                    <h5>
                        {message}
                    </h5>
        </Alert>
                      
                    </Modal.Body>
                    <Modal.Footer style={{ textAlign: "center" }}>
                      <Button  className="btn lmsbtn  btn-sm m-0" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                  </Modal>

              </div>

            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
