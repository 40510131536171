import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { fetchInterventionLabel, interventionsview } from "../../../services/ApiCaller/admin/intervention";
import ErrorMsg from "../../DefaultComponent/ErrorMsg";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
// import ApprovalEdit from "./ApprovalEditBatch";
// import ViewTestMaker from "./ViewContent";
import { scheduleAssessmentFilter } from "../../../services/ApiCaller/assessment/AssessmentSchedule/AssessmentScheduleController";
import { batchListInStatus } from "../../../services/ApiCaller/batch/batchController";
import { filterLessonByStatus } from "../../../services/ApiCaller/content/lesson";
import { filterUnitByStatus } from "../../../services/ApiCaller/content/unit";
import { userUIRole } from "../../../util/roleName";
import AuthorizedFunction from "../../PrivateRoute/AuthorizedFunction";

export default function AssessmentScheduleListApprover() {
  //view modal
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [testList, setTestList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  const [unitName, setUnitName] = useState([]);
  const [batchname, setBatchname] = useState([]);
  const [singleBatch, setSingleBatch] = useState('')
  const [multipleBatchIds, setMultipleBatchIds] = useState([])


  //UNIT STORE + HANDLE
  const [units, setUnits] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [lesson, setLesson] = useState([]);
  //INTERVENTION STORE + HANDLE
  const [allInterventions, setAllInterventions] = useState([]);
  const [interventionDataId, setInterventionDataid] = useState("");
  const [interventionName, setInterventionName] = useState("");
  const [interventionLevel, setInterventionLevel] = useState("");
  //LESSON STORE + HANDLE
  const [allLessons, setAllLessons] = useState([]);
  const [lessonId, setLessonId] = useState("");
  const [level, setLevel] = useState([]);
  const [year, setYear] = useState([]);
  const [school, setSchool] = useState([]);
  const [batch, setBatch] = useState([]);
 
  const [assessmentScheduleList, setAssessmentScheduleList] = useState([]);

     //MISSING ERRORS
     const [unitMissingError,setUnitMissingError]=useState(false)
     const [LabelMissingError,setLabelMissingError]=useState(false)
     const [LessonMissingError,setLessonMissingError]=useState(false)
     const [batchMissingError, setBatchMissingErr] = useState(false)

  useEffect(() => {
    fetchUrl();
    fetchList();
    fetchInterventionName();
  }, [interventionDataId, unitId, interventionLevel, lessonId]);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[4];
    setCurrentUrl(`/${urlElelement}`);
  };


  //fetch interventions
  const fetchInterventionName = () => {
    // setLoading(true)
    setAllInterventions([]);
    const response = interventionsview();
    response.then(
      (success) => {
        setLoading(false);
        Object.values(success).map((item) => {
          let single = {
            value: item.interventionId,
            id: item.id,
            label: item.interventionName,
          };
          setAllInterventions((prevdata) => [...prevdata, single]);
        });
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const fetchLables=(interventionId)=>{
    setAllLabels([])
    const resp = fetchInterventionLabel(interventionId)
    resp.then((success)=>{
      if(Object.values(success).length===0){
        setLabelMissingError(true)
     }else{
      Object.values(success).map((item) => {
        setLabelMissingError(false)
        let single = {
          value: item.id,
          label: item.interventionLevels,
        };
        setAllLabels((prevdata) => [...prevdata, single]);
      });
     } 
    })
  }
  
  

  //FETCH INTERVENTION AND MAP IT TO DROPDOWN BOX
  const fetchuintName = () => {
    setUnitName([]);
    setUnitMissingError(false)
    const response = filterUnitByStatus(["Approved"]);
    response.then((success) => {
      const data_by_InterventionId =  Object.values(success).filter(item => item.intervention.id===interventionDataId);
      const data_by_Intervention_Label_Id = Object.values(data_by_InterventionId).filter(item =>item.level.id === interventionLevel)
      console.log(data_by_Intervention_Label_Id)
      if(Object.values(data_by_Intervention_Label_Id).length===0){
        setUnitMissingError(true)
     }else{
       setUnitMissingError(false)
      Object.values(data_by_Intervention_Label_Id).map((item) => {
        let singleItem = {
          label: item.name,
          value: item.id,
        };
        setUnitName((prevdata) => [...prevdata, singleItem]);
      });
     } 
    });
  };
  const fetchlessontName = () => {
    setLesson([]);
    //ONLY APPROVED
    const response = filterLessonByStatus(["Approved"]);
    response.then((success) => {
      const data_by_InterventionId =  Object.values(success).filter(item => item.unit.intervention.id===interventionDataId);
       const data_by_Intervention_Label_Id = Object.values(data_by_InterventionId).filter(item => item.unit.level.id===interventionLevel);
       if(Object.values(data_by_Intervention_Label_Id).length===0){
        setLessonMissingError(true)
     }
     else{
      setLessonMissingError(false)
      Object.values(data_by_Intervention_Label_Id).map((item) => {
       let singleItem = {
         label: item.name,
         value: item.id,
       };
       setLesson((prevdata) => [...prevdata, singleItem]);
     });
     }
      
    });
  };

  const fetchBatchName = () => {
    // setLoading(true)
    setBatchname([]);
    setBatchMissingErr(false)
    const response = batchListInStatus([
      "Approved"
    ]);
    response.then((success) => {
      // setLoading(false)
      const data_by_InterventionId = Object.values(success).filter(item => item.interventionDTO.id === interventionDataId);
      const data_by_Intervention_Label_Id = Object.values(data_by_InterventionId).filter(item => item.level.id === interventionLevel)
      if (Object.values(data_by_Intervention_Label_Id).length === 0) {
        setBatchMissingErr(true)
      }
      else {
        setBatchMissingErr(false)
        Object.values(data_by_Intervention_Label_Id).map((item) => {
          let singleItem = {
            label: item.batchName,
            value: item.id,
            // schoolName:item.schoolDTO.schoolName,
            // schoolId:item.schoolDTO.schoolId,
            // academicYear:item.academicYear
          };
          setBatchname((prevData) => [...prevData, singleItem]);
        });
      }
    });
  };
  
  const handleBatch = (value) => {
    let str;
    if (value != null) {
      setSingleBatch(value.label)
      // setErrorintervention(false)
      prepareMultipleBatchArray(value);
    } else {
      str = null;
      // setErrorintervention(false)
      prepareMultipleBatchArray(str);
    }
  };
  const prepareMultipleBatchArray = (value) => {
    let dummyArr = [];
    Object.values(value).map((item) => {
      dummyArr.push(item.id);
      setMultipleBatchIds(dummyArr);
    });
  };
  




  // Filter List
  const fetchList = () => {
    const Data = JSON.stringify({
      interventionId: interventionDataId,
      unitId: unitId,
      interventionLevelId: interventionLevel,
      lessonId: lessonId,
      // "batchId": 0,
      // "schoolId": "string",
      // "academicYear": "string",
      // "academicYear": "string",
      statuses: ["Approved"],
    });
    console.log(Data);
    const response = scheduleAssessmentFilter(Data);
    response.then(
      (success) => {

        
        setLoading(false);
        setAssessmentScheduleList(success);
        console.log("dsuccesssuccess"+success)
        
      },
      (error) => {
        setLoading(false);
        setErrorObj(error);
        setShowpopup(true);
        setPopup(true);
        setAssessmentScheduleList([]);
      }
    );
  };

  const [columnDefs] = useState([
    { headerName: "Sr. No.", valueGetter: "node.rowIndex + 1", maxWidth: 120 },
    {
      headerName: "Intervention",
      field: "assessment.lesson.unit.intervention.interventionName",
    },
    {
      headerName: "Academic Year",
      field: "assessment.lesson.unit.academicYear",
    },
    {
      headerName: "Level",
      field: "assessment.lesson.unit.level.interventionLevels",
    },
    { headerName: "Unit", field: "assessment.lesson.unit.name" },
    { headerName: "Lesson", field: "assessment.lesson.name" },
    { headerName: "Type of Assessment", field: "assessment.mode" },
    { headerName: "Assessment Name", field: "assessment.name" },
    { headerName: "School Name", field: "batch,schoolDTO.schoolName" },
    { headerName: "Batch Name", field: "batch.batchName" },
    { headerName: "Start Date and Time", field: "" },
    { headerName: "End Date and Time", field: "" },
    { headerName: "Status", field: "assessment.status" },
    {
      headerName: "Actions",
      filter: false,
      cellRendererFramework: (params) => (
        <div>
          <div className="btn-group d-flex justify-content-center">
            {AuthorizedFunction([userUIRole.batch_creation_view]) && (
              <button
                type="button"
                className="btn btnview"
                onClick={() => viewTest(params.data)}
              >
                {" "}
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
            )}
            {AuthorizedFunction([userUIRole.batch_creation_edit]) && (
              <button
                type="button"
                className={
                  "btn btnedit "
                  //  +
                  // (params.data.status === "Approved" ? "disabled" : "") +
                  // (params.data.status === "delete" ? "disabled" : "")
                }
                onClick={() => updateModal(params.data)}
              >
                <i className="fa fa-edit" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const viewTest = (data) => {
    navigate(`/AssessmentScheduleViewApprover/${data.id}`);
  };

  //UPDATE/EDIT
  const updateModal = (data) => {
    navigate(`/AssessmentScheduleApprovalEdit/${data.id}`);
  };
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
    };
  }, []);
  const gridRef = useRef();
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "",
      backgroundColor: state.isSelected ? "#003366" : "",
      padding: 20,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1px solid black",
      boxShadow: "none",
      borderRadius: "0",
      "&:hover": {
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        borderBottom: "1px solid black",
        borderRadius: "0",
      },
    }),
  };
  const {
    formState: { errors },
    control,
  } = useForm();
  return (
    <>
      <Header />
      <div className={loading ? "app backwhite" : "app"}>
        <Sidebar url={currentUrl} />
        {!loading ? (
          <>
            <main>
              {/* page title */}
              <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">Assessment Schedule List</div>
                </div>
              </div>

              {/* main page  */}
              <div className="mainpage">
                <div calssName="App">
                  <div calssName="col d-flex justify-content-end"></div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row justify-content-center ">
                        <div className="form-group formgroup col-md-6 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idSelectIntervention"
                            className="labelheading "
                          >
                            Select Intervention
                          </label>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = allInterventions.find(
                                (c) => c.value === value
                              );
                              const handleInterventions = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setInterventionDataid(selectedOption.id);
                                  fetchLables(selectedOption.id);
                                  setInterventionName(selectedOption.label)
                                  fetchuintName();
                                  fetchlessontName();
                                  fetchBatchName();
                                } else {
                                  str = null;
                                  setInterventionDataid(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allInterventions}
                                  onChange={handleInterventions}
                                />
                              );
                            }}
                            name="interventionName"
                          />
                        </div>
                        <div className="form-group  formgroup col-md-3 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select Intervention Level
                          </label>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = allLabels.find(
                                (c) => c.value === value
                              );
                              const handleInterventionLevel = (
                                selectedOption
                              ) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setInterventionLevel(selectedOption.value);
                                  fetchuintName();
                                  fetchlessontName();
                                  fetchBatchName();
                                } else {
                                  str = null;
                                  setInterventionLevel(str);
                                }
                              };

                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={allLabels}
                                  onChange={handleInterventionLevel}
                                />
                              );
                            }}
                            name="InterventionLevel"
                          />
                          {LabelMissingError ?(
                          <small className="text-danger">Label Not Present Against {interventionName} </small>
                        ) : ("")}
                        {errors?.level?.type && (
                          <small className="text-danger">
                            {errors.level.message}
                          </small>
                        )}
                        </div>
                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select Unit
                          </label>
                          {/* role */}
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = unitName.find(
                                (c) => c.value === value
                              );
                              const handleUnit = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setUnitId(selectedOption.value);
                                } else {
                                  str = null;
                                  setUnitId(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={unitName}
                                  onChange={handleUnit}
                                />
                              );
                            }}
                            name="Unit"
                          />
                          {
                          unitMissingError ?(
                          <small className="text-danger">Unit Not Present Against {interventionName} </small>
                        ) : ("")}
                        {errors?.unit?.type && (
                          <small className="text-danger">
                            {errors.unit.message}
                          </small>
                        )}
                        </div>
                        <div className="form-group formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label
                            htmlFor="idSelectIntervention"
                            className="labelheading "
                          >
                            Select Lesson
                          </label>
                          {/* type */}
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = lesson.find(
                                (c) => c.value === value
                              );
                              //HANDLE USER TYPE HANDLER
                              const handleLesson = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setLessonId(selectedOption.value);
                                } else {
                                  str = null;
                                  setLessonId(str);
                                }
                              };

                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={lesson}
                                  onChange={handleLesson}
                                />
                              );
                            }}
                            name="lesson"
                          />
                          {
                          LessonMissingError ?(
                          <small className="text-danger">Lesson Not Present Against {interventionName} </small>
                        ) : ("")}
                        {errors?.lesson?.type && (
                          <small className="text-danger">
                            {errors.lesson.message}
                          </small>
                        )}
                        </div>
                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select Academic Year
                          </label>
                          {/* role */}
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = year.find(
                                (c) => c.value === value
                              );
                              const handleYear = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setYear(selectedOption.value);
                                } else {
                                  str = null;
                                  setYear(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={year}
                                  onChange={handleYear}
                                />
                              );
                            }}
                            name="Year"
                          />
                        </div>

                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select School
                          </label>
                          {/* role */}
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = school.find(
                                (c) => c.value === value
                              );
                              const handleSchool = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setSchool(selectedOption.value);
                                } else {
                                  str = null;
                                  setSchool(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={school}
                                  onChange={handleSchool}
                                />
                              );
                            }}
                            name="School"
                          />
                        </div>

                        <div className="form-group  formgroup col-md-4 col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-sm-12 col-12">
                          <label htmlFor="idRole" className="labelheading">
                            Select Batch
                          </label>
                          {/* role */}
                          {/* <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              const currentSelection = batch.find(
                                (c) => c.value === value
                              );
                              const handleBatch = (selectedOption) => {
                                onChange(selectedOption?.value);
                                let str;
                                if (selectedOption != null) {
                                  setBatch(selectedOption.value);
                                } else {
                                  str = null;
                                  setBatch(str);
                                }
                              };
                              return (
                                <Select
                                  isClearable
                                  styles={customStyles}
                                  className="Selectontrol"
                                  menuColor="red"
                                  inputRef={ref}
                                  value={currentSelection}
                                  name={name}
                                  options={batch}
                                  onChange={handleBatch}
                                />
                              );
                            }}
                            name="Batch"
                          /> */}
                          <Select
                          isClearable={true}
                          styles={customStyles}
                          isMulti={true}
                          className="Selectontrol"
                          defaultValue={singleBatch}
                          onChange={handleBatch}
                          options={batchname}
                        />
                          {
                          batchMissingError ?(
                          <small className="text-danger">Batch Not Present Against {interventionName} </small>
                        ) : ("")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col agPagediv">
                      Page Size:
                      <select onChange={onPageSizeChanged} id="page-size">
                        <option value="10" selected={true}>
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col agfilterdiv">
                      {" "}
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search..."
                        className="agfilter"
                        onInput={onFilterTextBoxChanged}
                      />
                    </div>
                  </div>
                </div>

                <div className="ag-theme-alpine agtheme">
                  <AgGridReact
                    rowData={testList}
                    paginationPageSize={10}
                    ref={gridRef} // Ref for accessing Grid's API
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressRowClickSelection={true}
                    groupSelectsChildren={true}
                    rowSelection={"multiple"}
                    rowGroupPanelShow={"always"}
                    pivotPanelShow={"always"}
                    enableRangeSelection={true}
                    pagination={true}
                  ></AgGridReact>
                </div>
              </div>
            </main>

            {showPopup ? (
              <ErrorMsg
                errorStatus={errorObj}
                setTrigger={setShowpopup}
                trigger={popup}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
