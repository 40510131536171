import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { interventionsview } from "../../../services/ApiCaller/admin/intervention";
import othericon from "../../../template/img/other.svg";
import pdficon from "../../../template/img/pdf.svg";
import ppticon from "../../../template/img/ppt.svg";
import videoicon from "../../../template/img/video.svg";
import Loader from "../../DefaultComponent/Loader";
import Header from "../../layout/Header";
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";


export default function LessonDetails() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [LessonnameUrl, setLessonnameUrl] = useState("");

  const [interventionName, setInterventionName] = useState("");
  const [interventionId, setInterventionId] = useState("");
  const [interventionDataId, setInterventionDataid] = useState("");
  const [selectedIntervention, setSelectedIntervention] = useState('')
  const [allInterventions, setAllInterventions] = useState([]);
  const [batch, setBatch] = useState([]);
  const [level, setLevel] = useState([]);
  const [school, setSchool] = useState([]);
  const { details } = useParams()
  useEffect(() => {
    fetchUrl();
    fetchInterventionName();
    setLessonnameUrl(details)
  }, [interventionDataId]);
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    setCurrentUrl(`/${urlElelement}`);
  };


  //fetch interventions
  const fetchInterventionName = () => {
    setLoading(true)
    setAllInterventions([])
    const response = interventionsview();
    response.then((success) => {

      setLoading(false)
      Object.values(success).map((item) => {
        let single = {
          value: item.interventionId,
          id: item.id,
          label: item.interventionName,
        };
        setAllInterventions((prevdata) => [...prevdata, single]);
      });
    }, error => {
      setLoading(false)
    });
  };


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#ffffff' : '',
      backgroundColor: state.isSelected ? '#003366' : '',
      padding: 20,
    }),
    control: (base, state) => ({
      ...base,
      borderTop: '0',
      borderLeft: '0',
      borderRight: '0',
      borderBottom: '1px solid black',
      boxShadow: 'none',
      borderRadius: "0",
      '&:hover': {
        borderTop: '0',
        borderLeft: '0',
        borderRight: '0',
        borderBottom: '1px solid black',
        borderRadius: "0",
      }
    })
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control
  } = useForm();

  return (
    <>
      <Header />
      <div className=" app">
        {/* Sidebar */}
        <Sidebar url={currentUrl} />
        {/* main comp */}
        <main>
          {!loading ? (
            <>
              {/* <div className="row pageheader justify-content-between">
                <div className="col">
                  <div className="pagetitle">
                    <button
                      type="button"
                      title="Back to batch list"
                      className="btn backbtn  btn-sm"
                      onClick={() => navigate("/Materials")}
                    >
                      <i class="fa-solid fa-circle-arrow-left"></i>
                    </button>
                    Lesson</div>
                </div>
              </div> */}
              <div className="mainpage">
                <div className="App">

                  <div className="">

                    <div className="row justify-content-between">
                      <div className="col">
                        <button
                          type="button"
                          className="btn pagetitlename  btn-sm"
                          onClick={() => navigate("/Materials")}
                        >
                          {/* {LessonnameUrl} */}
                        </button>
                      </div>
                    </div>
                    <div className="pagetitle ps-0"> </div>


                    <div className="row">

                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 col-sm-12 my-3 ">
                        <article className="leaderboard">
                          <header>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999" style={{ enableBackground: 'new 0 0 511.999 511.999' }} xmlSpace="preserve" className="leaderboard__icon">
                              <g>
                                <g>
                                  <path d="M466.45,49.374c-7.065-8.308-17.368-13.071-28.267-13.071H402.41v-11.19C402.41,11.266,391.143,0,377.297,0H134.705
			c-13.848,0-25.112,11.266-25.112,25.112v11.19H73.816c-10.899,0-21.203,4.764-28.267,13.071
			c-6.992,8.221-10.014,19.019-8.289,29.624c9.4,57.8,45.775,108.863,97.4,136.872c4.717,11.341,10.059,22.083,16.008,32.091
			c19.002,31.975,42.625,54.073,68.627,64.76c2.635,26.644-15.094,51.885-41.794,57.9c-0.057,0.013-0.097,0.033-0.153,0.046
			c-5.211,1.245-9.09,5.921-9.09,11.513v54.363h-21.986c-19.602,0-35.549,15.947-35.549,35.549v28.058
			c0,6.545,5.305,11.85,11.85,11.85H390.56c6.545,0,11.85-5.305,11.85-11.85v-28.058c0-19.602-15.947-35.549-35.549-35.549h-21.988
			V382.18c0-5.603-3.893-10.286-9.118-11.52c-0.049-0.012-0.096-0.028-0.145-0.04c-26.902-6.055-44.664-31.55-41.752-58.394
			c25.548-10.86,48.757-32.761,67.479-64.264c5.949-10.009,11.29-20.752,16.008-32.095c51.622-28.01,87.995-79.072,97.395-136.87
			C476.465,68.392,473.443,57.595,466.45,49.374z M60.652,75.192c-0.616-3.787,0.431-7.504,2.949-10.466
			c2.555-3.004,6.277-4.726,10.214-4.726h35.777v21.802c0,34.186,4.363,67.3,12.632,97.583
			C89.728,153.706,67.354,116.403,60.652,75.192z M366.861,460.243c6.534,0,11.85,5.316,11.85,11.85v16.208H134.422v-16.208
			c0-6.534,5.316-11.85,11.85-11.85H366.861z M321.173,394.03v42.513H191.96V394.03H321.173z M223.037,370.331
			c2.929-3.224,5.607-6.719,8.002-10.46c7.897-12.339,12.042-26.357,12.228-40.674c4.209,0.573,8.457,0.88,12.741,0.88
			c4.661,0,9.279-0.358,13.852-1.036c0.27,19.239,7.758,37.45,20.349,51.289H223.037z M378.709,81.803
			c0,58.379-13.406,113.089-37.747,154.049c-23.192,39.03-53.364,60.525-84.956,60.525c-31.597,0-61.771-21.494-84.966-60.523
			c-24.342-40.961-37.748-95.671-37.748-154.049V25.112c0-0.78,0.634-1.413,1.412-1.413h242.591c0.78,0,1.414,0.634,1.414,1.413
			V81.803z M451.348,75.192c-6.702,41.208-29.074,78.51-61.569,104.191c8.268-30.283,12.631-63.395,12.631-97.58V60.001h35.773
			c3.938,0,7.66,1.723,10.214,4.726C450.915,67.688,451.963,71.405,451.348,75.192z" />
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M327.941,121.658c-1.395-4.288-5.103-7.414-9.566-8.064l-35.758-5.196l-15.991-32.402
			c-1.997-4.044-6.116-6.605-10.626-6.605c-4.511,0-8.63,2.561-10.626,6.605l-15.991,32.402l-35.758,5.196
			c-4.464,0.648-8.172,3.775-9.566,8.065c-1.393,4.291-0.231,8.999,2.999,12.148l25.875,25.221l-6.109,35.613
			c-0.763,4.446,1.064,8.938,4.714,11.59c3.648,2.651,8.487,3,12.479,0.902L256,190.32l31.982,16.813
			c1.734,0.911,3.627,1.36,5.512,1.36c2.456,0,4.902-0.763,6.966-2.263c3.65-2.652,5.477-7.144,4.714-11.59l-6.109-35.613
			l25.875-25.221C328.172,130.658,329.334,125.949,327.941,121.658z M278.064,146.405c-2.793,2.722-4.068,6.644-3.408,10.489
			l3.102,18.09l-16.245-8.541c-1.725-0.908-3.62-1.36-5.514-1.36c-1.894,0-3.788,0.454-5.514,1.36l-16.245,8.541l3.102-18.09
			c0.66-3.844-0.615-7.766-3.408-10.489l-13.141-12.81l18.162-2.64c3.859-0.56,7.196-2.985,8.922-6.482l8.123-16.458l8.122,16.458
			c1.727,3.497,5.062,5.921,8.922,6.482l18.162,2.64L278.064,146.405z" />
                                </g>
                              </g>
                            </svg>
                            <h1 className="leaderboard__title"><span className="leaderboard__title--bottom">Leaderboard</span></h1>
                          </header>
                          <main className="leaderboard__profiles">
                            <article className="leaderboard__profile">
                              <img src={pdficon} className="leaderboard__picture" />
                              <span className="leaderboard__name">Mark Zuckerberg</span>
                              <button className="leaderboard__value btn"><i class="fas fa-play"></i></button>
                            </article>
                            <article className="leaderboard__profile">
                              <img src={ppticon} alt="Dustin Moskovitz" className="leaderboard__picture" />
                              <span className="leaderboard__name">Dustin Moskovitz</span>
                              <button className="leaderboard__value btn"><i class="fas fa-play"></i></button>

                            </article>
                            <article className="leaderboard__profile">
                              <img src={videoicon} alt="Elizabeth Holmes" className="leaderboard__picture" />
                              <span className="leaderboard__name">Elizabeth Holmes</span>
                              <button className="leaderboard__value btn"><i class="fas fa-play"></i></button>

                            </article>
                            <article className="leaderboard__profile">
                              <img src={othericon} alt="Evan Spiegel" className="leaderboard__picture" />
                              <span className="leaderboard__name">Evan Spiegel</span>
                              <button className="leaderboard__value btn"><i class="fas fa-play"></i></button>
                            </article>
                          </main>
                        </article>

                      </div>
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 col-sm-12 my-3 ">


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )
          }
        </main >
      </div >
    </>
  );
}
