import { useEffect } from "react";
import FileViewer from "react-file-viewer";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import PdfViewer from "./PdfViewer";

export default function LoadContent(props) {
  const { fileType, filePath } = props;
  console.log("LoadContent", filePath, fileType);
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  useEffect(() => {}, [filePath]);

  // const pdfContentType = 'application/pdf';
  // const base64toBlob = (data) => {
  //     // Cut the prefix `data:application/pdf;base64` from the raw base 64
  //     const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

  //     const bytes = atob(base64WithoutPrefix);
  //     let length = bytes.length;
  //     let out = new Uint8Array(length);

  //     while (length--) {
  //         out[length] = bytes.charCodeAt(length);
  //     }

  //     return new Blob([out], { type: pdfContentType });
  // };

  // const url = URL.createObjectURL(base64toBlob(filePath));

  const getFileType = (type) => {
    return (type === "xls" || type === "xlsx") ? "xlsx" : type
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card-body">
              <div className="cert-ht" style={{ height: "600px"}} key={Math.random()}>
                {/* if we want to load comp dynamically */}
                {filePath && (
                  <>
                    {" "}
                    {fileType == "pdf" ? (
                      <PdfViewer
                        fileType={fileType}
                        filePath={filePath}
                      ></PdfViewer>
                    ) : (
                      <FileViewer
                        fileType={getFileType(fileType)}
                        filePath={filePath}
                        onError={onError}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
