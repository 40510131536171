
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { contentFilter, contentFilterUnit } from '../../../services/ApiCaller/content/content';
import { leaderDownloadfile, pptDownLoad } from '../../../services/ApiCaller/file/fileManagement';
import docicon from "../../../template/img/doc_icon.svg";
import othericon from "../../../template/img/other.svg";
import pdficon from "../../../template/img/pdf.svg";
import audioicon from "../../../template/img/sound.svg";
import videoicon from "../../../template/img/video.svg";
import htmlicon from "../../../template/img/html.svg";
import ErrorMsg from '../../DefaultComponent/ErrorMsg';
import SuccessMsg from '../../DefaultComponent/SuccessMsg'
import Loader from '../../DefaultComponent/Loader';
import imgicon from "../../../template/img/image_icon.svg";
import Header from '../../layout/Header';
import "../../layout/layout.scss";
import Sidebar from "../../layout/Sidebar";
import AudioViewer from './AudioViewer';
import ImageViewerContent from './ImageViewerContent';
import LoadContent from './LoadContent';
import PdfViewer from './PdfViewer';
import ScromViewer from './ScromViewer';
import VideoPlayer from './VideoPlayer';
import HtmlViewer from "./HtmlViewer";
import YoutubePlayer from "./YoutubePlayer";
import YouTube from 'react-youtube';
import yticon from'./yt.svg';
import CourseItem from './CourseItem';
// import YoutubeViewer from "../Approver/YoutubePlayer";
import { successMessages } from '../../../util/Messaging';

export default function LessonContents() {
      const navigate = useNavigate();
      const location = useLocation();
      const [unitName, setUnitName] = useState('')
    const { lessonId } = useParams();
    const [data, setdata]=useState([])
    const manageRedirection = (courseItemData) => {
      const regex = /[^a-zA-Z0-9\s]/g;
      const cName = courseItemData.name.replace(regex, "-")
      console.log(`${cName}/${courseItemData.id}`,"gtesin")
      navigate(`${cName}/${courseItemData.id}`, {state: courseItemData});
  
    };
    useEffect(()=>{
      const unitid = location.pathname.split('/').filter((x) => x)[1]
      console.log("location", unitid)
      if(unitid)
      {
        console.log("location.state",location)
        contentFilterUnit(unitid).then(res=>setdata(res))
      }
    },[lessonId])
    function rednerCard(){
      return data.map(item=>{
        // return  <RecentCourses image = {yticon} courseName = {item.name} module = '4'  duration='4' currentStatus = '40' completionStatus={item.completedPercentage}/>
       return <CourseItem
       module="6"
       currentStatus="100"
       completionStatus="100"
       manageRedirection={manageRedirection}
       description=""
       coursedata={item}
       buttonEnable={true}
     />
      })

    }
    const [allContents, setAllContents] = useState([])
    const [fileType,setFileType]=useState('')
    const [videoId,setVideoId]=useState('')
  const[filePath,setFilePath]=useState('')
  const [scromFileId,setScromFileId]=useState('')
  const [htmlFileId, setHtmlFileId] = useState('')
  const [audioFileId,setAudioFileId]=useState('')
  const [loading,setLoading]=useState(false)
  const [lessonName,setLessonName]=useState('')
  const [pptFileId, setPptFileId] = useState('')
  const [youtubeFileId, setYoutubeFileId] = useState('')
  //Error Msgs
  const [showPopup, setShowpopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [getlessonId, setGetlessonId] = useState();
  const [active, setActive] = useState("");
  //SHOW sUCCESS MSGS
  const [successMsg, setSuccessMsg] = useState("")
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [triggerSuccess, setTriggerSuccess] = useState(false)
  
 
  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  const opts = {
    height: '390',
    width: '840',
    playerVars: {
      controls: 1,       // Show video controls
      disablekb: 1,      // Disable keyboard controls
      modestbranding: 1, // Hide YouTube logo
      rel: 0,            // Hide related videos
      showinfo: 0,       // Hide video title and uploader info
      playsinline: 1,
    }
  };
  
    useEffect(() => {
    fetchContent()
    },[])
    const fetchContent = () =>{
      setLoading(true)
    setAllContents([])
    const Data = JSON.stringify({
      "lessonId": lessonId,
      "unitId":location.state
    })
    const response = contentFilter(Data);
    response.then(
      (success) => {
        setLoading(false)
        console.log("resp",success)
        success.sort((a, b) => {
          return a.contentIndex - b.contentIndex;
      });
        const lesson_name = Object.values(success)[0].lesson.name;
        const unit_name =  Object.values(success)[0].lesson.unit.name
        setLessonName(lesson_name)
        setUnitName(unit_name)
        Object.values(
          success.filter((success) => success.beneficiaryAccess === true && success.status === "Approved")
        ).map((item) => {
          setAllContents((prevData) => [...prevData, item])
          console.log("item", item);
          
        });
        // Object.values(
        //   success.filter((success) => success.teacherAccess === false && success.beneficiaryAccess === true)
        // ).map((item) => {
        //   console.log("item", item);
        //   setAllContents([item])
        // });
        // Object.values(success).map((item) => {
        //   setGetlessonId(item.id)
        //   setLessonName(item.lesson.name)
        // });
      },
      (error) => {
        setLoading(false)
        setErrorObj(error);
        setShowpopup(true);
        setPopup(true);
      }
    );
  }
    const fetchFileDetails = (file) => {
    setActive(file.name)
    console.log("file",file.source)
    if( file.source==="FileMgmtService" && file.type!="pptx" && file.type!="ppt" && file.type!="scorm" && file.type!="html" && file.type!="zip" && file.source!="Vimeo" && file.source!="MistServer"){
      setFileType(file.type)
      const response = leaderDownloadfile(file.fileId)
      response.then(
        (success) => {
          console.log("success", success.data)
          setFilePath(success.data)
        },
        (error) => {
          setLoading(false)
          setErrorObj(error);
          setShowpopup(true);
          setPopup(true);
        }
      );
    }
    if(file.source==="Vimeo"){
      setFileType(file.type)
      console.log("file is", file.fileId)
      setVideoId(file.fileId)
    }
    if(file.source==="MistServer"){
      setFileType(file.type)
      setAudioFileId(file.fileId)
    }
    if(file.type==="scorm" || file.type==="zip"){
      setFileType(file.type)
      setScromFileId(file.fileId)
      
    }
    if(file.type==="html" || file.type==="zip"){
      setFileType(file.type)
      setHtmlFileId(file.fileId)
      
    }
    if( file.type==="ppt" || file.type==="pptx"){
      setFileType(file.type)
      setPptFileId(file.fileId)
    }
    // if (file.type === "youtube" || file.type === "h5p") {
    //   setFileType(file.type)
    //   setVideoId(file.fileId)
    // }
    if (file.type.toLowerCase() === "youtube" || file.type === "h5p") {
      setFileType(file.type)
      setVideoId(file.fileId)
    }
  }
  const renderComp = () => {
    if ( 
    fileType === "avi" || 
    fileType === "mov" ||
    fileType === "mp4" ||
    fileType === "wmv"||
    fileType === "flv") {
      return <VideoPlayer videoId={videoId} />
    }
    else if (fileType === "pdf") {
      return <LoadContent fileType={fileType} filePath={filePath} />
    }
    else if(fileType==="ppt" || fileType==="pptx"){
      return <button
      type="button"
      className="btn lmsbtn  btn-sm"
      onClick={() => downloadPPt(pptFileId)}
    >Download PPT</button>
    }
    else if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
      return <ImageViewerContent filePath={filePath} />
    }
    else if(fileType === "scorm" || fileType === "zip" ){
      return <ScromViewer fileId={scromFileId}/>
    }
    else if(fileType === "html" || fileType === "zip" ){
      return <HtmlViewer fileId={htmlFileId}/>
    }
    else if (fileType === "docx") {
      return <LoadContent fileType={fileType} filePath={filePath} />
    }
    else if(fileType === "mp3"){
      return <AudioViewer fileId={audioFileId}/>
    }
    // else if (fileType === "youtube" || fileType === "h5p") {
    //   return <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
    // }
    else if (fileType.toLowerCase() === "youtube" || fileType === "h5p") {
      return <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
    }
  }

  // const downloadPPt = (data)=>{
  //   const resp = pptDownLoad(data)
  //   resp.then((success) => {
  //       console.log(success)
  //       setLoading(false)
  //       setSuccessMsg(successMessages.Form_Submit.download_Success)
  //       setShowSuccessMsg(true)
  //       setTriggerSuccess(true)
  //   }, error => {
  //       setErrorObj(error)
  //       setLoading(false)
  //       setShowpopup(true);
  //       setPopup(true)
  //   })
  // }

  const downloadPPt = (data)=>{
    
    const resp = pptDownLoad(data)
    resp.then((success) => {
        console.log(success)
        setLoading(false)
        setSuccessMsg(successMessages.Form_Submit.download_Success)
        setShowSuccessMsg(true)
        setTriggerSuccess(true)
    }, error => {
        setErrorObj(error)
        setLoading(false)
        setShowpopup(true);
        setPopup(true)
    })
  }

  return (
  <>
  <Header />
  <div className='app'>
    <Sidebar />
  <main className="mainbg">
          {
            !loading ?

            <div class="card ms-3 border-0 mt-4 w-100">
              <div className="row justify-content-between mb-2">
              <div className="col">
                    <button
                      type="button"
                      className="btn backbtnunit  btn-sm"
                     
                    >
                      <i class="fa-solid fa-book-bookmark" onClick={() => navigate("/Materials")} ></i>
                    </button>
                    <div className='d-inline-block me-1'>
                      <a className="text-primary text-decoration-underline itemhover"
                        onClick={() => navigate("/Materials")} >
                        unit:{unitName}  

                      </a>
                    </div> 
                    <span> / </span>
                    <div className='d-inline-block'>

                      <a className="text-primary text-decoration-underline itemhover cstm_style"
                        onClick={() => 
                          {
                            navigate(`/Lesson/${location.state}`)
                          }
                        }
                      >lesson Name : {lessonName && lessonName}</a>
                    </div>
                  </div>
                    </div>
                          <div className="row">
                            <div className="col-12">
                              
                            {/* {allContents && allContents.map((content, index) => {
                                    return (
                                      <div 
                                      // className="contentdiv"
                                      className={active === content.name ? 'activecontentdiv' : 'contentdiv'}
                                      key={index}>
                                        <div 
                                        onClick={()=>
                                        { 
                                          fetchFileDetails(content)}
                                        }
                                        >
                                           { console.log("content",content)}
                                           {
                                          content.type === "pdf" ?
                                          <img src={pdficon} className="leaderboard__picture" />:
                                          content.type==="png" || content.type==="jpeg" || content.type==="jpg"?
                                          <img src={imgicon} className="leaderboard__picture" />:
                                          content.type==="docx"?
                                          <img src={docicon} className="leaderboard__picture" />:
                                          content.type==="scorm"?
                          
                                          <img src={othericon} className="leaderboard__picture" />:
                                          content.source==="MistServer"?
                                          <img src={audioicon} className="leaderboard__picture" />:
                                          content.source==="Vimeo"?
                                          <img src={videoicon} className="leaderboard__picture" />
                                          :
                                          content.type==="html"?
                                          <img src={htmlicon} className="leaderboard__picture" />
                                          : content.type.toLowerCase() === "youtube"?<img src={yticon} className="leaderboard__picture" />:
                                          <img src={othericon} className="leaderboard__picture" />
                                        }
                            <p>{content.name}</p>
                                        </div>
                                      </div>
                                    )
                            })} */}
                            </div>
                          </div>
                          <div className="row mt-5">
                          <div className="row">
                          {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-sm-12"> */}
                                        {/* <div className='row'> */}
                                            {/* <RecentCourses image = {finance} courseName = 'Financial planning' module = '3' duration='3' currentStatus = '50' completionStatus='50'/> */}
                                            {/* <RecentCourses image = {yticon} courseName = 'Banking' module = '4'  duration='4' currentStatus = '40' completionStatus='40'/> */}
                                            {/* <RecentCourses image = {yticon} courseName = 'Financial planning' module = '6'  duration='6' currentStatus = '100' completionStatus='100'/> */}
                                            {/* {rednerCard()} */}
                                        {/* </div> */}
                                    {/* </div> */}
                                    <div className="row">
          {data &&
            data.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-sm-12">
                  <CourseItem
                    module="6"
                    currentStatus="100"
                    completionStatus="100"
                    manageRedirection={manageRedirection}
                    description=""
                    coursedata={item}
                    buttonEnable={true}
                  />
                </div>
              );
            })}
        </div>
                                    </div>
                            <div className="col-12 Sessionbtn">
                            {/* {renderComp()} */}
                            {/* {console.log(videoId,"youtubeFileId")} */}
                    
                          {
   showPopup ?
   <ErrorMsg
       body={errorObj}
       setTrigger={setShowpopup}
       trigger={popup}
   />:''
}
{showSuccessMsg ? (
                                  <SuccessMsg
                                    msg={successMsg}
                                    setTrigger={setShowSuccessMsg}
                                    trigger={triggerSuccess}
                                  />
                                ) : (
                                  ""
                                )}
    
                            </div>
                          </div>
                          {/* load content dynamically */}
                        
                        </div>
                        :
                        <Loader />

          }
        
        </main>
  </div>
  </>
  )
}
