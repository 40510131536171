import axios from "axios";

//filter Unit
export function filterUnit(data) {
    return  new Promise((resolve,reject)=>{ 
     axios.post('content/api/v1/lms-content/unit/filter',data,{
       headers: {
           //'Access-Control-Allow-Origin':'*',
           "Accept": "application/json",
           "Content-Type": "application/json"
       }
     })
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

  //filter unit by status
export function filterUnitByStatus(data) {
  return  new Promise((resolve,reject)=>{ 
   axios.post('content/api/v1/lms-content/unit/getByStatuses',data,{
     headers: {
         //'Access-Control-Allow-Origin':'*',
         "Accept": "application/json",
         "Content-Type": "application/json"
     }
   })
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}



  export function filtergetByUserIdUnit(id) {
    return  new Promise((resolve,reject)=>{ 
     axios.get(`content/api/v1/lms-content/unit/getByUserId/${id}/false`)
    //  axios.post(`assessment/api/v1/lms_assessment/schedule/student/getByUserId/${id}`)
       .then(function (response) {
         resolve(response.data);
       })
       .catch(function (error) {
         reject(error);
       });
     });
  }

  //fetch unit List
export function unitList() {
  return  new Promise((resolve,reject)=>{ 
   axios.get('content/api/v1/lms-content/unit/getAll')
     .then(function (response) {
       resolve(response.data);
     })
     .catch(function (error) {
       reject(error);
     });
   });
}
export function fetchCertificateList(data) {
  return new Promise((resolve, reject) => {
    axios
      .post("assessment/api/v1/lms_assessment/getCertificateListByUser", data, {
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function fetchCertificateListView(cerId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`assessment/api/v1/lms_assessment/getCerDetails/${cerId}`,{
        headers: {
          //'Access-Control-Allow-Origin':'*',
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}