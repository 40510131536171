export const CORRECT_ANSWER = "Correct Answer"
export const MEDIUM_SCREEN_PIXEL_SIZE = 768
export const APP_NAME = ""
export const UN_AUTHORIZED = "UNAUTHORIZED"
export const AUTHORIZED = "AUTHORIZED"
export const MULTIPART = "AUTHORIZED"
export const ASSESSMENT = "Assessment"
export const MOCK = "Mock"
export const DATA_NOT_FOUND = 400

export const SUBMIT_QUIZ = "Do you want to submit Quiz ?"
export const SUBMIT_EXAM = "Do you want to submit Exam ?"
export const QUIZ_ENABLE_LABEL = "Quiz is enabled"
// Dashboard
// User Management
//   Create Admin 
//   Create User 
//   User Upload History 
// Content Management
//   Create Course
//   Create Module
//   Upload Content
// Edit Course Mapping
// Approve Content
//   Approve Course
//   Approve Module
//   Approve Content
// Create Test
//   Upload Quiz Bank
//   Create Exam
// Approve Test
//   Approve Quiz
//   Approve Exam
// Reports
//  Beneficiary Reports
//  Rolewise Reports
//  Content Create
//  Content Approve
//  Course Report
//  Time Log Report
//  Content Status
//  Quiz Report
//  Exam Report
// export const appFeatures = [
//     {
//       "menu_item_title": "Dashboard",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "roles":[],
//       "submenu_item_data": [
//       ]
//     },
//     {
//       "menu_item_title": "Content Management",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Create Admin",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Create User",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "User Upload History",
//           "has_selected": false,
//           "roles":[]
//         }, 
//       ]
//     },
//     {
//       "menu_item_title": "User Management",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Create Course",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Create Module",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Upload Content",
//           "has_selected": false,
//           "roles":[]
//         }, 
//       ]
//     },
//     {
//       "menu_item_title": "Edit Course Mapping",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "roles":[],
//       "submenu_item_data": [
//       ]
//     },
//     {
//       "menu_item_title": "Approve Content",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Approve Course",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Approve Module",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Approve Content",
//           "has_selected": false,
//           "roles":[]
//         }, 
//       ]
//     },
//     {
//       "menu_item_title": "Create Test",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Upload Quiz Bank",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Create Exam",
//           "has_selected": false,
//           "roles":[]
//         }
//       ]
//     },
//     {
//       "menu_item_title": "Approve Test",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Approve Quiz",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Approve Exam",
//           "has_selected": false,
//           "roles":[]
//         }
//       ]
//     },
//     {
//       "menu_item_title": "Reports",
//       "has_child": false,
//       "icon": "",
//       "has_selected": false,
//       "submenu_item_data": [
//         {
//           "child_menu_item_title": "Beneficiary Reports",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Rolewise Reports",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Content Create",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Content Approve",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Course Report",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Time Log Report",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Content Status",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Quiz Report",
//           "has_selected": false,
//           "roles":[]
//         },
//         {
//           "child_menu_item_title": "Exam Report",
//           "has_selected": false,
//           "roles":[]
//         }
//       ]
//     },
//   ]

  export const mediaTypes = {
    'pdf' : 'data:application/pdf;base64,',
    'docx' : 'data: application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64',
    'doc' : 'data: application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64',
    'xls' : '',
    'xlsx' : '',
    'ppt' : '',
    'pptx' : '',
  }